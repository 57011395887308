import React, { useState, useEffect } from "react";
import { addAddress, getAddress, globalLoderAction } from "../Redux/Action";
import {
  // useSelector,
  useDispatch,
} from "react-redux";
import GetAddress from "./GetAddress";
import Select from "react-select";

import "./AddressForm.css";
function AddressForm(setActiveTab) {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [pincodeError, setPincodeError] = useState("");
  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [address, setAddress] = useState({
    state: "",
    address: "",
    primary: "",

    addressType: "",
  });
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, [dispatch]);
  const handleChange = (e) => {
    if (e.target.name === "zipcode") {
      if (!/^[0-9]{10}$/.test(e.target.value)) {
        setPincodeError("Please enter a valid Pincode");
      } else {
        setPincodeError("");
      }
    }
    if (e.target.name === "phone") {
      if (!/^[0-9]*$/.test(e.target.value)) {
        setPhoneError("Phone number should contain only numbers");
      } else if (e.target.value.length !== 10) {
        setPhoneError("Phone number must be exactly 10 digits");
      } else {
        setPhoneError("");
      }
    }

    const isValid =
      /^[A-Za-z\s.,-]*$/.test(e.target.value) &&
      /[A-Za-z]/.test(e.target.value);

    if (!isValid) {
      setError("City contain only symbols like ., -");
    } else {
      setError(""); // Clear error if input is valid
    }
    setAddress({ ...address, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(addAddress(address))
      .then((res) => {
        setLoad(false);
        dispatch(getAddress());
        setActiveTab(1);
        setAddress({
          // Reset to initial state
          state: "",
          address: "",
          primary: "",
          addressType: "",
          phone: "", // Ensure 'phone' is included in the state
          zipcode: "", // If you have a pincode/zipcode field, add here as well
        });
      })
      .catch((err) => {
        //
      });
  };
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  const stateOptions = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Delhi", label: "Delhi" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Orissa", label: "Orissa" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];
  const districtOptions = [
    { value: "Abhayapuri", label: "Abhayapuri" },
    { value: "Achabbal", label: "Achabbal" },
    { value: "Achhnera", label: "Achhnera" },
    { value: "Adalaj", label: "Adalaj" },
    { value: "Adari", label: "Adari" },
    { value: "Adilabad", label: "Adilabad" },
    { value: "Adityana", label: "Adityana" },
    { value: "Adoni", label: "Adoni" },
    { value: "Adoor", label: "Adoor" },
    { value: "Adra", label: "Adra" },
    { value: "Agartala", label: "Agartala" },
    { value: "Agra", label: "Agra" },
    { value: "Ahiwara", label: "Ahiwara" },
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Ahmedgarh", label: "Ahmedgarh" },
    { value: "Ahmednagar", label: "Ahmednagar" },
    { value: "Aizawl", label: "Aizawl" },
    { value: "Ajmer", label: "Ajmer" },
    { value: "Akaltara", label: "Akaltara" },
    { value: "Akathiyoor", label: "Akathiyoor" },
    { value: "Akhnoor", label: "Akhnoor" },
    { value: "Akola", label: "Akola" },
    { value: "Alang", label: "Alang" },
    { value: "Alappuzha", label: "Alappuzha" },
    { value: "Aldona", label: "Aldona" },
    { value: "Aligarh", label: "Aligarh" },
    { value: "Alipurduar", label: "Alipurduar" },
    { value: "Allahabad", label: "Allahabad" },
    { value: "Almora", label: "Almora" },
    { value: "Along", label: "Along" },
    { value: "Alwar", label: "Alwar" },
    { value: "Amadalavalasa", label: "Amadalavalasa" },
    { value: "Amalapuram", label: "Amalapuram" },
    { value: "Amarpur", label: "Amarpur" },
    { value: "Ambagarh chowki", label: "Ambagarh chowki" },
    { value: "Ambaji", label: "Ambaji" },
    { value: "Ambala", label: "Ambala" },
    { value: "Ambaliyasan", label: "Ambaliyasan" },
    { value: "Ambikapur", label: "Ambikapur" },
    { value: "Amguri", label: "Amguri" },
    { value: "Amlabad", label: "Amlabad" },
    { value: "Amli", label: "Amli" },
    { value: "Amravati", label: "Amravati" },
    { value: "Amreli", label: "Amreli" },
    { value: "Amritsar", label: "Amritsar" },
    { value: "Amroha", label: "Amroha" },
    { value: "Anakapalle", label: "Anakapalle" },
    { value: "Anand", label: "Anand" },
    { value: "Anandapur", label: "Anandapur" },
    { value: "Anandnagaar", label: "Anandnagaar" },
    { value: "Anantapur", label: "Anantapur" },
    { value: "Anantnag", label: "Anantnag" },
    { value: "Ancharakandy", label: "Ancharakandy" },
    { value: "Andada", label: "Andada" },
    { value: "Anjar", label: "Anjar" },
    { value: "Anklav", label: "Anklav" },
    { value: "Ankleshwar", label: "Ankleshwar" },
    { value: "Antaliya", label: "Antaliya" },
    { value: "Anugul", label: "Anugul" },
    { value: "Ara", label: "Ara" },
    { value: "Arakkonam", label: "Arakkonam" },
    { value: "Arambagh", label: "Arambagh" },
    { value: "Arambhada", label: "Arambhada" },
    { value: "Arang", label: "Arang" },
    { value: "Araria", label: "Araria" },
    { value: "Arasikere", label: "Arasikere" },
    { value: "Arcot", label: "Arcot" },
    { value: "Areraj", label: "Areraj" },
    { value: "Arki", label: "Arki" },
    { value: "Arnia", label: "Arnia" },
    { value: "Aroor", label: "Aroor" },
    { value: "Arrah", label: "Arrah" },
    { value: "Aruppukkottai", label: "Aruppukkottai" },
    { value: "Asankhurd", label: "Asankhurd" },
    { value: "Asansol", label: "Asansol" },
    { value: "Asarganj", label: "Asarganj" },
    { value: "Ashok nagar", label: "Ashok nagar" },
    { value: "Ashtamichira", label: "Ashtamichira" },
    { value: "Asika", label: "Asika" },
    { value: "Asola", label: "Asola" },
    { value: "Assandh", label: "Assandh" },
    { value: "Ateli", label: "Ateli" },
    { value: "Attingal", label: "Attingal" },
    { value: "Atul", label: "Atul" },
    { value: "Aurangabad", label: "Aurangabad" },
    { value: "Avinissery", label: "Avinissery" },
    { value: "Awantipora", label: "Awantipora" },
    { value: "Azamgarh", label: "Azamgarh" },
    { value: "Babiyal", label: "Babiyal" },
    { value: "Baddi", label: "Baddi" },
    { value: "Bade bacheli", label: "Bade bacheli" },
    { value: "Badepalle", label: "Badepalle" },
    { value: "Badharghat", label: "Badharghat" },
    { value: "Bagaha", label: "Bagaha" },
    { value: "Bahadurganj", label: "Bahadurganj" },
    { value: "Bahadurgarh", label: "Bahadurgarh" },
    { value: "Baharampur", label: "Baharampur" },
    { value: "Bahraich", label: "Bahraich" },
    { value: "Bairgania", label: "Bairgania" },
    { value: "Bakhtiarpur", label: "Bakhtiarpur" },
    { value: "Balaghat", label: "Balaghat" },
    { value: "Balangir", label: "Balangir" },
    { value: "Balasore", label: "Balasore" },
    { value: "Baleshwar", label: "Baleshwar" },
    { value: "Bali", label: "Bali" },
    { value: "Ballabhgarh", label: "Ballabhgarh" },
    { value: "Ballia", label: "Ballia" },
    { value: "Bally", label: "Bally" },
    { value: "Balod", label: "Balod" },
    { value: "Baloda bazar", label: "Baloda bazar" },
    { value: "Balrampur", label: "Balrampur" },
    { value: "Balurghat", label: "Balurghat" },
    { value: "Bamra", label: "Bamra" },
    { value: "Banda", label: "Banda" },
    { value: "Bandikui", label: "Bandikui" },
    { value: "Bandipore", label: "Bandipore" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Banganapalle", label: "Banganapalle" },
    { value: "Banka", label: "Banka" },
    { value: "Bankura", label: "Bankura" },
    { value: "Banmankhi bazar", label: "Banmankhi bazar" },
    { value: "Banswara", label: "Banswara" },
    { value: "Bapatla", label: "Bapatla" },
    { value: "Barahiya", label: "Barahiya" },
    { value: "Barakar", label: "Barakar" },
    { value: "Baramati", label: "Baramati" },
    { value: "Baramula", label: "Baramula" },
    { value: "Baran", label: "Baran" },
    { value: "Barasat", label: "Barasat" },
    { value: "Barauli", label: "Barauli" },
    { value: "Barbigha", label: "Barbigha" },
    { value: "Barbil", label: "Barbil" },
    { value: "Bardhaman", label: "Bardhaman" },
    { value: "Bareilly", label: "Bareilly" },
    { value: "Bargarh", label: "Bargarh" },
    { value: "Barh", label: "Barh" },
    { value: "Baripada", label: "Baripada" },
    { value: "Barmer", label: "Barmer" },
    { value: "Barnala", label: "Barnala" },
    { value: "Barpeta", label: "Barpeta" },
    { value: "Barpeta road", label: "Barpeta road" },
    { value: "Barughutu", label: "Barughutu" },
    { value: "Barwala", label: "Barwala" },
    { value: "Basudebpur", label: "Basudebpur" },
    { value: "Batala", label: "Batala" },
    { value: "Bathinda", label: "Bathinda" },
    { value: "Bazpur", label: "Bazpur" },
    { value: "Begusarai", label: "Begusarai" },
    { value: "Behea", label: "Behea" },
    { value: "Belgaum", label: "Belgaum" },
    { value: "Bellampalle", label: "Bellampalle" },
    { value: "Bellary", label: "Bellary" },
    { value: "Belpahar", label: "Belpahar" },
    { value: "Bemetra", label: "Bemetra" },
    { value: "Bethamcherla", label: "Bethamcherla" },
    { value: "Bettiah", label: "Bettiah" },
    { value: "Betul", label: "Betul" },
    { value: "Bhabua", label: "Bhabua" },
    { value: "Bhadrachalam", label: "Bhadrachalam" },
    { value: "Bhadrak", label: "Bhadrak" },
    { value: "Bhagalpur", label: "Bhagalpur" },
    { value: "Bhagha purana", label: "Bhagha purana" },
    { value: "Bhainsa", label: "Bhainsa" },
    { value: "Bharuch", label: "Bharuch" },
    { value: "Bhatapara", label: "Bhatapara" },
    { value: "Bhavani", label: "Bhavani" },
    { value: "Bhavnagar", label: "Bhavnagar" },
    { value: "Bhawanipatna", label: "Bhawanipatna" },
    { value: "Bheemunipatnam", label: "Bheemunipatnam" },
    { value: "Bhimavaram", label: "Bhimavaram" },
    { value: "Bhiwani", label: "Bhiwani" },
    { value: "Bhongir", label: "Bhongir" },
    { value: "Bhopal", label: "Bhopal" },
    { value: "Bhuban", label: "Bhuban" },
    { value: "Bhubaneswar", label: "Bhubaneswar" },
    { value: "Bhuj", label: "Bhuj" },
    { value: "Bidhan nagar", label: "Bidhan nagar" },
    { value: "Bihar sharif", label: "Bihar sharif" },
    { value: "Bikaner", label: "Bikaner" },
    { value: "Bikramganj", label: "Bikramganj" },
    { value: "Bilasipara", label: "Bilasipara" },
    { value: "Bilaspur", label: "Bilaspur" },
    { value: "Biramitrapur", label: "Biramitrapur" },
    { value: "Birgaon", label: "Birgaon" },
    { value: "Bobbili", label: "Bobbili" },
    { value: "Bodh gaya", label: "Bodh gaya" },
    { value: "Bodhan", label: "Bodhan" },
    { value: "Bokaro steel city", label: "Bokaro steel city" },
    { value: "Bomdila", label: "Bomdila" },
    { value: "Bongaigaon", label: "Bongaigaon" },
    { value: "Brahmapur", label: "Brahmapur" },
    { value: "Brajrajnagar", label: "Brajrajnagar" },
    { value: "Budhlada", label: "Budhlada" },
    { value: "Burhanpur", label: "Burhanpur" },
    { value: "Buxar", label: "Buxar" },
    { value: "Byasanagar", label: "Byasanagar" },
    { value: "Calcutta", label: "Calcutta" },
    { value: "Cambay", label: "Cambay" },
    { value: "Chaibasa", label: "Chaibasa" },
    { value: "Chakradharpur", label: "Chakradharpur" },
    { value: "Chalakudy", label: "Chalakudy" },
    { value: "Chalisgaon", label: "Chalisgaon" },
    { value: "Chamba", label: "Chamba" },
    { value: "Champa", label: "Champa" },
    { value: "Champhai", label: "Champhai" },
    { value: "Chamrajnagar", label: "Chamrajnagar" },
    { value: "Chandan bara", label: "Chandan bara" },
    { value: "Chandausi", label: "Chandausi" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chandrapura", label: "Chandrapura" },
    { value: "Changanassery", label: "Changanassery" },
    { value: "Chanpatia", label: "Chanpatia" },
    { value: "Charkhi dadri", label: "Charkhi dadri" },
    { value: "Chatra", label: "Chatra" },
    { value: "Cheeka", label: "Cheeka" },
    { value: "Chendamangalam", label: "Chendamangalam" },
    { value: "Chengalpattu", label: "Chengalpattu" },
    { value: "Chengannur", label: "Chengannur" },
    { value: "Chennai", label: "Chennai" },
    { value: "Cherthala", label: "Cherthala" },
    { value: "Cheruthazham", label: "Cheruthazham" },
    { value: "Chhapra", label: "Chhapra" },
    { value: "Chhatarpur", label: "Chhatarpur" },
    { value: "Chikkaballapur", label: "Chikkaballapur" },
    { value: "Chikmagalur", label: "Chikmagalur" },
    { value: "Chilakaluripet", label: "Chilakaluripet" },
    { value: "Chinchani", label: "Chinchani" },
    { value: "Chinna salem", label: "Chinna salem" },
    { value: "Chinsura", label: "Chinsura" },
    { value: "Chintamani", label: "Chintamani" },
    { value: "Chirala", label: "Chirala" },
    { value: "Chirkunda", label: "Chirkunda" },
    { value: "Chirmiri", label: "Chirmiri" },
    { value: "Chitradurga", label: "Chitradurga" },
    { value: "Chittoor", label: "Chittoor" },
    { value: "Chittur-thathamangalam", label: "Chittur-thathamangalam" },
    { value: "Chockli", label: "Chockli" },
    { value: "Churi", label: "Churi" },
    { value: "Coimbatore", label: "Coimbatore" },
    { value: "Colgong", label: "Colgong" },
    { value: "Contai", label: "Contai" },
    { value: "Cooch behar", label: "Cooch behar" },
    { value: "Coonoor", label: "Coonoor" },
    { value: "Cuddalore", label: "Cuddalore" },
    { value: "Cuddapah", label: "Cuddapah" },
    { value: "Curchorem cacora", label: "Curchorem cacora" },
    { value: "Cuttack", label: "Cuttack" },
    { value: "Dabra", label: "Dabra" },
    { value: "Dadri", label: "Dadri" },
    { value: "Dahod", label: "Dahod" },
    { value: "Dalhousie", label: "Dalhousie" },
    { value: "Dalli-rajhara", label: "Dalli-rajhara" },
    { value: "Dalsinghsarai", label: "Dalsinghsarai" },
    { value: "Daltonganj", label: "Daltonganj" },
    { value: "Daman and diu", label: "Daman and diu" },
    { value: "Darbhanga", label: "Darbhanga" },
    { value: "Darjeeling", label: "Darjeeling" },
    { value: "Dasua", label: "Dasua" },
    { value: "Datia", label: "Datia" },
    { value: "Daudnagar", label: "Daudnagar" },
    { value: "Davanagere", label: "Davanagere" },
    { value: "Debagarh", label: "Debagarh" },
    { value: "Deesa", label: "Deesa" },
    { value: "Dehradun", label: "Dehradun" },
    { value: "Dehri-on-sone", label: "Dehri-on-sone" },
    { value: "Delhi", label: "Delhi" },
    { value: "Deoghar", label: "Deoghar" },
    { value: "Deoria", label: "Deoria" },
    { value: "Devarakonda", label: "Devarakonda" },
    { value: "Devgarh", label: "Devgarh" },
    { value: "Dewas", label: "Dewas" },
    { value: "Dhaka", label: "Dhaka" },
    { value: "Dhamtari", label: "Dhamtari" },
    { value: "Dhanbad", label: "Dhanbad" },
    { value: "Dhar", label: "Dhar" },
    { value: "Dharampur", label: "Dharampur" },
    { value: "Dharamsala", label: "Dharamsala" },
    { value: "Dharmanagar", label: "Dharmanagar" },
    { value: "Dharmapuri", label: "Dharmapuri" },
    { value: "Dharmavaram", label: "Dharmavaram" },
    { value: "Dharwad", label: "Dharwad" },
    { value: "Dhekiajuli", label: "Dhekiajuli" },
    { value: "Dhenkanal", label: "Dhenkanal" },
    { value: "Dholka", label: "Dholka" },
    { value: "Dhubri", label: "Dhubri" },
    { value: "Dhule", label: "Dhule" },
    { value: "Dhuri", label: "Dhuri" },
    { value: "Dibrugarh", label: "Dibrugarh" },
    { value: "Digboi", label: "Digboi" },
    { value: "Dighwara", label: "Dighwara" },
    { value: "Dimapur", label: "Dimapur" },
    { value: "Dinanagar", label: "Dinanagar" },
    { value: "Dindigul", label: "Dindigul" },
    { value: "Diphu", label: "Diphu" },
    { value: "Dipka", label: "Dipka" },
    { value: "Dispur", label: "Dispur" },
    { value: "Dombivli", label: "Dombivli" },
    { value: "Dongargarh", label: "Dongargarh" },
    { value: "Dumka", label: "Dumka" },
    { value: "Dumraon", label: "Dumraon" },
    { value: "Durg-bhilai nagar", label: "Durg-bhilai nagar" },
    { value: "Durgapur", label: "Durgapur" },
    { value: "Ellenabad 2", label: "Ellenabad 2" },
    { value: "Eluru", label: "Eluru" },
    { value: "Erattupetta", label: "Erattupetta" },
    { value: "Erode", label: "Erode" },
    { value: "Etawah", label: "Etawah" },
    { value: "Faridabad", label: "Faridabad" },
    { value: "Faridkot", label: "Faridkot" },
    { value: "Farooqnagar", label: "Farooqnagar" },
    { value: "Fatehabad", label: "Fatehabad" },
    { value: "Fatehabad", label: "Fatehabad" }, // Duplicate

    { value: "Fatehpur", label: "Fatehpur" },
    { value: "Fatehpur", label: "Fatehpur" }, // Duplicate

    { value: "Fatwah", label: "Fatwah" },
    { value: "Fazilka", label: "Fazilka" },
    { value: "Firozpur", label: "Firozpur" },
    { value: "Firozpur cantt.", label: "Firozpur cantt." },
    { value: "Forbesganj", label: "Forbesganj" },
    { value: "Gadag", label: "Gadag" },
    { value: "Gadwal", label: "Gadwal" },
    { value: "Ganaur", label: "Ganaur" },
    { value: "Gandhinagar", label: "Gandhinagar" },
    { value: "Gangtok", label: "Gangtok" },
    { value: "Garhwa", label: "Garhwa" },
    { value: "Gauripur", label: "Gauripur" },
    { value: "Gaya", label: "Gaya" },
    { value: "Gharaunda", label: "Gharaunda" },
    { value: "Ghatshila", label: "Ghatshila" },
    { value: "Giddarbaha", label: "Giddarbaha" },
    { value: "Giridih", label: "Giridih" },
    { value: "Goalpara", label: "Goalpara" },
    { value: "Gobindgarh", label: "Gobindgarh" },
    { value: "Gobranawapara", label: "Gobranawapara" },
    { value: "Godda", label: "Godda" },
    { value: "Godhra", label: "Godhra" },
    { value: "Gogri jamalpur", label: "Gogri jamalpur" },
    { value: "Gohana", label: "Gohana" },
    { value: "Golaghat", label: "Golaghat" },
    { value: "Gomoh", label: "Gomoh" },
    { value: "Gooty", label: "Gooty" },
    { value: "Gopalganj", label: "Gopalganj" },
    { value: "Greater noida", label: "Greater noida" },
    { value: "Gudalur", label: "Gudalur" },
    { value: "Gudalur", label: "Gudalur" }, // Duplicate

    { value: "Gudivada", label: "Gudivada" },
    { value: "Gudur", label: "Gudur" },
    { value: "Gulbarga", label: "Gulbarga" },
    { value: "Gumia", label: "Gumia" },
    { value: "Gumla", label: "Gumla" },
    { value: "Gundlupet", label: "Gundlupet" },
    { value: "Guntakal", label: "Guntakal" },
    { value: "Guntur", label: "Guntur" },
    { value: "Gunupur", label: "Gunupur" },
    { value: "Gurdaspur", label: "Gurdaspur" },
    { value: "Gurgaon", label: "Gurgaon" },
    { value: "Guruvayoor", label: "Guruvayoor" },
    { value: "Guwahati", label: "Guwahati" },
    { value: "Gwalior", label: "Gwalior" },
    { value: "Haflong", label: "Haflong" },
    { value: "Haibat(yamuna nagar)", label: "Haibat(yamuna nagar)" },
    { value: "Hailakandi", label: "Hailakandi" },
    { value: "Hajipur", label: "Hajipur" },
    { value: "Haldia", label: "Haldia" },
    { value: "Haldwani", label: "Haldwani" },
    { value: "Hamirpur", label: "Hamirpur" },
    { value: "Hamirpur", label: "Hamirpur" }, // Duplicate
    { value: "Hansi", label: "Hansi" },
    { value: "Hanuman junction", label: "Hanuman junction" },
    { value: "Hardoi", label: "Hardoi" },
    { value: "Haridwar", label: "Haridwar" },
    { value: "Hassan", label: "Hassan" },
    { value: "Hazaribag", label: "Hazaribag" },
    { value: "Hilsa", label: "Hilsa" },
    { value: "Himatnagar", label: "Himatnagar" },
    { value: "Hindupur", label: "Hindupur" },
    { value: "Hinjilicut", label: "Hinjilicut" },
    { value: "Hisar", label: "Hisar" },
    { value: "Hisua", label: "Hisua" },
    { value: "Hodal", label: "Hodal" },
    { value: "Hojai", label: "Hojai" },
    { value: "Hoshiarpur", label: "Hoshiarpur" },
    { value: "Hospet", label: "Hospet" },
    { value: "Howrah", label: "Howrah" },
    { value: "Hubli", label: "Hubli" },
    { value: "Hussainabad", label: "Hussainabad" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Ichalkaranji", label: "Ichalkaranji" },
    { value: "Ichchapuram", label: "Ichchapuram" },
    { value: "Idar", label: "Idar" },
    { value: "Imphal", label: "Imphal" },
    { value: "Indore", label: "Indore" },
    { value: "Indranagar", label: "Indranagar" },
    { value: "Irinjalakuda", label: "Irinjalakuda" },
    { value: "Islampur", label: "Islampur" },
    { value: "Islampur", label: "Islampur" }, // Duplicate
    { value: "Itanagar", label: "Itanagar" },
    { value: "Itarsi", label: "Itarsi" },
    { value: "Jabalpur", label: "Jabalpur" },
    { value: "Jagatsinghapur", label: "Jagatsinghapur" },
    { value: "Jagdalpur", label: "Jagdalpur" },
    { value: "Jagdispur", label: "Jagdispur" },
    { value: "Jaggaiahpet", label: "Jaggaiahpet" },
    { value: "Jagraon", label: "Jagraon" },
    { value: "Jagtial", label: "Jagtial" },
    { value: "Jaipur", label: "Jaipur" },
    { value: "Jaisalmer", label: "Jaisalmer" },
    { value: "Jaitu", label: "Jaitu" },
    { value: "Jajapur", label: "Jajapur" },
    { value: "Jajmau", label: "Jajmau" },
    { value: "Jalalabad", label: "Jalalabad" },
    { value: "Jalandhar", label: "Jalandhar" },
    { value: "Jalandhar cantt.", label: "Jalandhar cantt." },
    { value: "Jaleswar", label: "Jaleswar" },
    { value: "Jalna", label: "Jalna" },
    { value: "Jamalpur", label: "Jamalpur" },
    { value: "Jammalamadugu", label: "Jammalamadugu" },
    { value: "Jammu", label: "Jammu" },
    { value: "Jamnagar", label: "Jamnagar" },
    { value: "Jamshedpur", label: "Jamshedpur" },
    { value: "Jamtara", label: "Jamtara" },
    { value: "Jamui", label: "Jamui" },
    { value: "Jandiala", label: "Jandiala" },
    { value: "Jangaon", label: "Jangaon" },
    { value: "Janjgir", label: "Janjgir" },
    { value: "Jashpurnagar", label: "Jashpurnagar" },
    { value: "Jaspur", label: "Jaspur" },
    { value: "Jatani", label: "Jatani" },
    { value: "Jaunpur", label: "Jaunpur" },
    { value: "Jehanabad", label: "Jehanabad" },
    { value: "Jeypur", label: "Jeypur" },
    { value: "Jhajha", label: "Jhajha" },
    { value: "Jhajjar", label: "Jhajjar" },
    { value: "Jhanjharpur", label: "Jhanjharpur" },
    { value: "Jhansi", label: "Jhansi" },
    { value: "Jhargram", label: "Jhargram" },
    { value: "Jharsuguda", label: "Jharsuguda" },
    { value: "Jhumri tilaiya", label: "Jhumri tilaiya" },
    { value: "Jind", label: "Jind" },
    { value: "Joda", label: "Joda" },
    { value: "Jodhpur", label: "Jodhpur" },
    { value: "Jogabani", label: "Jogabani" },
    { value: "Jogendranagar", label: "Jogendranagar" },
    { value: "Jorhat", label: "Jorhat" },
    { value: "Jowai", label: "Jowai" },
    { value: "Junagadh", label: "Junagadh" },
    { value: "Kadapa", label: "Kadapa" },
    { value: "Kadi", label: "Kadi" },
    { value: "Kadiri", label: "Kadiri" },
    { value: "Kadirur", label: "Kadirur" },
    { value: "Kagaznagar", label: "Kagaznagar" },
    { value: "Kailasahar", label: "Kailasahar" },
    { value: "Kaithal", label: "Kaithal" },
    { value: "Kakching", label: "Kakching" },
    { value: "Kakinada", label: "Kakinada" },
    { value: "Kalan wali", label: "Kalan wali" },
    { value: "Kalavad", label: "Kalavad" },
    { value: "Kalka", label: "Kalka" },
    { value: "Kalliasseri", label: "Kalliasseri" },
    { value: "Kalol", label: "Kalol" },
    { value: "Kalpetta", label: "Kalpetta" },
    { value: "Kalpi", label: "Kalpi" },
    { value: "Kalyan", label: "Kalyan" },
    { value: "Kalyandurg", label: "Kalyandurg" },
    { value: "Kamareddy", label: "Kamareddy" },
    { value: "Kanchipuram", label: "Kanchipuram" },
    { value: "Kandukur", label: "Kandukur" },
    { value: "Kanhangad", label: "Kanhangad" },
    { value: "Kanjikkuzhi", label: "Kanjikkuzhi" },
    { value: "Kanker", label: "Kanker" },
    { value: "Kannur", label: "Kannur" },
    { value: "Kanpur", label: "Kanpur" },
    { value: "Kantabanji", label: "Kantabanji" },
    { value: "Kanti", label: "Kanti" },
    { value: "Kapadvanj", label: "Kapadvanj" },
    { value: "Kapurthala", label: "Kapurthala" },
    { value: "Karaikal", label: "Karaikal" },
    { value: "Karaikudi", label: "Karaikudi" },
    { value: "Karanjia", label: "Karanjia" },
    { value: "Karimganj", label: "Karimganj" },
    { value: "Karimnagar", label: "Karimnagar" },
    { value: "Karjan", label: "Karjan" },
    { value: "Karkala", label: "Karkala" },
    { value: "Karnal", label: "Karnal" },
    { value: "Karoran", label: "Karoran" },
    { value: "Kartarpur", label: "Kartarpur" },
    { value: "Karungal", label: "Karungal" },
    { value: "Karur", label: "Karur" },
    { value: "Karwar", label: "Karwar" },
    { value: "Kasaragod", label: "Kasaragod" },
    { value: "Kashipur", label: "Kashipur" },
    { value: "Kathua", label: "Kathua" },
    { value: "Katihar", label: "Katihar" },
    { value: "Katni", label: "Katni" },
    { value: "Kavali", label: "Kavali" },
    { value: "Kavaratti", label: "Kavaratti" },
    { value: "Kawardha", label: "Kawardha" },
    { value: "Kayamkulam", label: "Kayamkulam" },
    { value: "Kendrapara", label: "Kendrapara" },
    { value: "Kendujhar", label: "Kendujhar" },
    { value: "Keshod", label: "Keshod" },
    { value: "Khagaria", label: "Khagaria" },
    { value: "Khambhalia", label: "Khambhalia" },
    { value: "Khambhat", label: "Khambhat" },
    { value: "Khammam", label: "Khammam" },
    { value: "Khanna", label: "Khanna" },
    { value: "Kharagpur", label: "Kharagpur" },
    { value: "Kharar", label: "Kharar" },
    { value: "Kheda", label: "Kheda" },
    { value: "Khedbrahma", label: "Khedbrahma" },
    { value: "Kheralu", label: "Kheralu" },
    { value: "Khordha", label: "Khordha" },
    { value: "Khowai", label: "Khowai" },
    { value: "Khunti", label: "Khunti" },
    { value: "Kichha", label: "Kichha" },
    { value: "Kishanganj", label: "Kishanganj" },
    { value: "Kochi", label: "Kochi" },
    { value: "Kodinar", label: "Kodinar" },
    { value: "Kodungallur", label: "Kodungallur" },
    { value: "Kohima", label: "Kohima" },
    { value: "Kokrajhar", label: "Kokrajhar" },
    { value: "Kolar", label: "Kolar" },
    { value: "Kolhapur", label: "Kolhapur" },
    { value: "Kolkata", label: "Kolkata" },
    { value: "Kollam", label: "Kollam" },
    { value: "Kollankodu", label: "Kollankodu" },
    { value: "Kondagaon", label: "Kondagaon" },
    { value: "Koothuparamba", label: "Koothuparamba" },
    { value: "Koraput", label: "Koraput" },
    { value: "Koratla", label: "Koratla" },
    { value: "Korba", label: "Korba" },
    { value: "Kot kapura", label: "Kot kapura" },
    { value: "Kota", label: "Kota" },
    { value: "Kotdwara", label: "Kotdwara" },
    { value: "Kothagudem", label: "Kothagudem" },
    { value: "Kothamangalam", label: "Kothamangalam" },
    { value: "Kothapeta", label: "Kothapeta" },
    { value: "Kotma", label: "Kotma" },
    { value: "Kottayam", label: "Kottayam" },
    { value: "Kovvur", label: "Kovvur" },
    { value: "Kozhikode", label: "Kozhikode" },
    { value: "Kunnamkulam", label: "Kunnamkulam" },
    { value: "Kurali", label: "Kurali" },
    { value: "Kurnool", label: "Kurnool" },
    { value: "Kyathampalle", label: "Kyathampalle" },
    { value: "Lachhmangarh", label: "Lachhmangarh" },
    { value: "Ladnu", label: "Ladnu" },
    { value: "Ladwa", label: "Ladwa" },
    { value: "Lahar", label: "Lahar" },
    { value: "Laharpur", label: "Laharpur" },
    { value: "Lakheri", label: "Lakheri" },
    { value: "Lakhimpur", label: "Lakhimpur" },
    { value: "Lakhisarai", label: "Lakhisarai" },
    { value: "Lakshmeshwar", label: "Lakshmeshwar" },
    { value: "Lal gopalganj nindaura", label: "Lal gopalganj nindaura" },
    { value: "Lalganj", label: "Lalganj" },
    { value: "Lalgudi", label: "Lalgudi" },
    { value: "Lalitpur", label: "Lalitpur" },
    { value: "Lalsot", label: "Lalsot" },
    { value: "Lanka", label: "Lanka" },
    { value: "Lar", label: "Lar" },
    { value: "Lathi", label: "Lathi" },
    { value: "Lawar", label: "Lawar" },
    { value: "Leh", label: "Leh" },
    { value: "Lembuchera", label: "Lembuchera" },
    { value: "Leningrad", label: "Leningrad" },
    { value: "Latur", label: "Latur" },
    { value: "Laxmipur", label: "Laxmipur" },
    { value: "Laxmanpur", label: "Laxmanpur" },
    { value: "Ledia", label: "Ledia" },
    { value: "Limbdi", label: "Limbdi" },
    { value: "Limbani", label: "Limbani" },
    { value: "Lonavala", label: "Lonavala" },
    { value: "Longowal", label: "Longowal" },
    { value: "Ludhiana", label: "Ludhiana" },
    { value: "Luknow", label: "Luknow" },
    { value: "Macherla", label: "Macherla" },
    { value: "Machilipatnam", label: "Machilipatnam" },
    { value: "Madanapalle", label: "Madanapalle" },
    { value: "Maddur", label: "Maddur" },
    { value: "Madgaon", label: "Madgaon" },
    { value: "Madhepura", label: "Madhepura" },
    { value: "Madhubani", label: "Madhubani" },
    { value: "Madhugiri", label: "Madhugiri" },
    { value: "Madhupur", label: "Madhupur" },
    { value: "Madikeri", label: "Madikeri" },
    { value: "Madurai", label: "Madurai" },
    { value: "Magadi", label: "Magadi" },
    { value: "Mahad", label: "Mahad" },
    { value: "Mahalingpur", label: "Mahalingpur" },
    { value: "Maharajganj", label: "Maharajganj" },
    { value: "Maharajpur", label: "Maharajpur" },
    { value: "Mahasamund", label: "Mahasamund" },
    { value: "Mahbubnagar", label: "Mahbubnagar" },
    { value: "Mahe", label: "Mahe" },
    { value: "Mahendragarh", label: "Mahendragarh" },
    { value: "Mahesana", label: "Mahesana" },
    { value: "Mahidpur", label: "Mahidpur" },
    { value: "Mahnar bazar", label: "Mahnar bazar" },
    { value: "Mahuli", label: "Mahuli" },
    { value: "Mahuva", label: "Mahuva" },
    { value: "Maihar", label: "Maihar" },
    { value: "Mainaguri", label: "Mainaguri" },
    { value: "Makhdumpur", label: "Makhdumpur" },
    { value: "Makrana", label: "Makrana" },
    { value: "Mal", label: "Mal" },
    { value: "Malajkhand", label: "Malajkhand" },
    { value: "Malappuram", label: "Malappuram" },
    { value: "Malavalli", label: "Malavalli" },
    { value: "Malegaon", label: "Malegaon" },
    { value: "Malerkotla", label: "Malerkotla" },
    { value: "Malkangiri", label: "Malkangiri" },
    { value: "Malkapur", label: "Malkapur" },
    { value: "Malout", label: "Malout" },
    { value: "Malpura", label: "Malpura" },
    { value: "Malur", label: "Malur" },
    { value: "Manasa", label: "Manasa" },
    { value: "Manavadar", label: "Manavadar" },
    { value: "Manawar", label: "Manawar" },
    { value: "Manchar", label: "Manchar" },
    { value: "Mancherial", label: "Mancherial" },
    { value: "Mandalgarh", label: "Mandalgarh" },
    { value: "Mandamarri", label: "Mandamarri" },
    { value: "Mandapeta", label: "Mandapeta" },
    { value: "Mandawa", label: "Mandawa" },
    { value: "Mandi", label: "Mandi" },
    { value: "Mandi dabwali", label: "Mandi dabwali" },
    { value: "Mandideep", label: "Mandideep" },
    { value: "Mandla", label: "Mandla" },
    { value: "Mandsaur", label: "Mandsaur" },
    { value: "Mandvi", label: "Mandvi" },
    { value: "Mandya", label: "Mandya" },
    { value: "Maner", label: "Maner" },
    { value: "Mangaldoi", label: "Mangaldoi" },
    { value: "Mangalore", label: "Mangalore" },
    { value: "Mangalvedhe", label: "Mangalvedhe" },
    { value: "Manglaur", label: "Manglaur" },
    { value: "Mangrol", label: "Mangrol" },
    { value: "Mangrulpir", label: "Mangrulpir" },
    { value: "Manihari", label: "Manihari" },
    { value: "Manjlegaon", label: "Manjlegaon" },
    { value: "Mankachar", label: "Mankachar" },
    { value: "Manmad", label: "Manmad" },
    { value: "Mansa", label: "Mansa" },
    { value: "Manuguru", label: "Manuguru" },
    { value: "Manvi", label: "Manvi" },
    { value: "Manwath", label: "Manwath" },
    { value: "Mapusa", label: "Mapusa" },
    { value: "Margao", label: "Margao" },
    { value: "Margherita", label: "Margherita" },
    { value: "Marhaura", label: "Marhaura" },
    { value: "Mariani", label: "Mariani" },
    { value: "Marigaon", label: "Marigaon" },
    { value: "Markapur", label: "Markapur" },
    { value: "Marmagao", label: "Marmagao" },
    { value: "Masaurhi", label: "Masaurhi" },
    { value: "Mathabhanga", label: "Mathabhanga" },
    { value: "Mathura", label: "Mathura" },
    { value: "Mattannur", label: "Mattannur" },
    { value: "Mauganj", label: "Mauganj" },
    { value: "Maur", label: "Maur" },
    { value: "Mavelikkara", label: "Mavelikkara" },
    { value: "Mavoor", label: "Mavoor" },
    { value: "Mayang imphal", label: "Mayang imphal" },
    { value: "Medak", label: "Medak" },
    { value: "Medinipur", label: "Medinipur" },
    { value: "Meerut", label: "Meerut" },
    { value: "Mehkar", label: "Mehkar" },
    { value: "Mehmedabad", label: "Mehmedabad" },
    { value: "Memari", label: "Memari" },
    { value: "Merta city", label: "Merta city" },
    { value: "Mhaswad", label: "Mhaswad" },
    { value: "Mhow cantonment", label: "Mhow cantonment" },
    { value: "Mhowgaon", label: "Mhowgaon" },
    { value: "Mihijam", label: "Mihijam" },
    { value: "Miraj", label: "Miraj" },
    { value: "Mirganj", label: "Mirganj" },
    { value: "Miryalaguda", label: "Miryalaguda" },
    { value: "Modasa", label: "Modasa" },
    { value: "Modinagar", label: "Modinagar" },
    { value: "Moga", label: "Moga" },
    { value: "Mogalthur", label: "Mogalthur" },
    { value: "Mohali", label: "Mohali" },
    { value: "Mokameh", label: "Mokameh" },
    { value: "Mokokchung", label: "Mokokchung" },
    { value: "Monoharpur", label: "Monoharpur" },
    { value: "Morena", label: "Morena" },
    { value: "Morinda", label: "Morinda" },
    { value: "Morshi", label: "Morshi" },
    { value: "Morvi", label: "Morvi" },
    { value: "Motihari", label: "Motihari" },
    { value: "Motipur", label: "Motipur" },
    { value: "Mount abu", label: "Mount abu" },
    { value: "Mudalgi", label: "Mudalgi" },
    { value: "Mughal sarai", label: "Mughal sarai" },
    { value: "Mundra", label: "Mundra" },
    { value: "Mundargi", label: "Mundargi" },
    { value: "Munnar", label: "Munnar" },
    { value: "Murshidabad", label: "Murshidabad" },
    { value: "Murwara", label: "Murwara" },
    { value: "Mussoorie", label: "Mussoorie" },
    { value: "Mysore", label: "Mysore" },
    { value: "Nabadwip", label: "Nabadwip" },
    { value: "Nabarangapur", label: "Nabarangapur" },
    { value: "Nabha", label: "Nabha" },
    { value: "Nadbai", label: "Nadbai" },
    { value: "Nadiad", label: "Nadiad" },
    { value: "Nagaon", label: "Nagaon" },
    { value: "Nagapattinam", label: "Nagapattinam" },
    { value: "Nagar", label: "Nagar" },
    { value: "Nagari", label: "Nagari" },
    { value: "Nagarkurnool", label: "Nagarkurnool" },
    { value: "Nagaur", label: "Nagaur" },
    { value: "Nagda", label: "Nagda" },
    { value: "Nagercoil", label: "Nagercoil" },
    { value: "Nagina", label: "Nagina" },
    { value: "Nagla", label: "Nagla" },
    { value: "Nagpur", label: "Nagpur" },
    { value: "Nahan", label: "Nahan" },
    { value: "Naharlagun", label: "Naharlagun" },
    { value: "Naihati", label: "Naihati" },
    { value: "Naila janjgir", label: "Naila janjgir" },
    { value: "Nainital", label: "Nainital" },
    { value: "Nainpur", label: "Nainpur" },
    { value: "Najibabad", label: "Najibabad" },
    { value: "Nakodar", label: "Nakodar" },
    { value: "Nakur", label: "Nakur" },
    { value: "Nalasopara", label: "Nalasopara" },
    { value: "Nalbari", label: "Nalbari" },
    { value: "Namagiripettai", label: "Namagiripettai" },
    { value: "Namakkal", label: "Namakkal" },
    { value: "Nanded-waghala", label: "Nanded-waghala" },
    { value: "Nandgaon", label: "Nandgaon" },
    { value: "Nandivaram-guduvancheri", label: "Nandivaram-guduvancheri" },
    { value: "Nandura", label: "Nandura" },
    { value: "Nandurbar", label: "Nandurbar" },
    { value: "Nandyal", label: "Nandyal" },
    { value: "Nangal", label: "Nangal" },
    { value: "Nanjangud", label: "Nanjangud" },
    { value: "Nanjikottai", label: "Nanjikottai" },
    { value: "Nanpara", label: "Nanpara" },
    { value: "Narasapur", label: "Narasapur" },
    { value: "Narasaraopet", label: "Narasaraopet" },
    { value: "Naraura", label: "Naraura" },
    { value: "Narayanpet", label: "Narayanpet" },
    { value: "Nargund", label: "Nargund" },
    { value: "Narkatiaganj", label: "Narkatiaganj" },
    { value: "Narkhed", label: "Narkhed" },
    { value: "Narnaul", label: "Narnaul" },
    { value: "Narsinghgarh", label: "Narsinghgarh" },
    { value: "Narsipatnam", label: "Narsipatnam" },
    { value: "Narwana", label: "Narwana" },
    { value: "Nashik", label: "Nashik" },
    { value: "Nasirabad", label: "Nasirabad" },
    { value: "Natham", label: "Natham" },
    { value: "Nathdwara", label: "Nathdwara" },
    { value: "Naugachhia", label: "Naugachhia" },
    { value: "Naugawan sadat", label: "Naugawan sadat" },
    { value: "Nautanwa", label: "Nautanwa" },
    { value: "Navalgund", label: "Navalgund" },
    { value: "Navi mumbai", label: "Navi mumbai" },
    { value: "Navsari", label: "Navsari" },
    { value: "Nawabganj", label: "Nawabganj" },
    { value: "Nawada", label: "Nawada" },
    { value: "Nawalgarh", label: "Nawalgarh" },
    { value: "Nawanshahr", label: "Nawanshahr" },
    { value: "Nawapur", label: "Nawapur" },
    { value: "Nedumangad", label: "Nedumangad" },
    { value: "Neem-ka-thana", label: "Neem-ka-thana" },
    { value: "Neemuch", label: "Neemuch" },
    { value: "Nehtaur", label: "Nehtaur" },
    { value: "Nelamangala", label: "Nelamangala" },
    { value: "Nellikuppam", label: "Nellikuppam" },
    { value: "Nellore", label: "Nellore" },
    { value: "Nepanagar", label: "Nepanagar" },
    { value: "Neyveli", label: "Neyveli" },
    { value: "Neyyattinkara", label: "Neyyattinkara" },
    { value: "Nidadavole", label: "Nidadavole" },
    { value: "Nilanga", label: "Nilanga" },
    { value: "Nimbahera", label: "Nimbahera" },
    { value: "Nipani", label: "Nipani" },
    { value: "Nirmal", label: "Nirmal" },
    { value: "Niwai", label: "Niwai" },
    { value: "Niwari", label: "Niwari" },
    { value: "Nizamabad", label: "Nizamabad" },
    { value: "Nohar", label: "Nohar" },
    { value: "Noida", label: "Noida" },
    { value: "Nokha", label: "Nokha" },
    { value: "Nongstoin", label: "Nongstoin" },
    { value: "Noorpur", label: "Noorpur" },
    { value: "North lakhimpur", label: "North lakhimpur" },
    { value: "Nowgong", label: "Nowgong" },
    { value: "Nowrozabad", label: "Nowrozabad" },
    { value: "Nuzvid", label: "Nuzvid" },
    { value: "O valley", label: "O valley" },
    { value: "Obra", label: "Obra" },
    { value: "Oddanchatram", label: "Oddanchatram" },
    { value: "Ongole", label: "Ongole" },
    { value: "Orai", label: "Orai" },
    { value: "Osmanabad", label: "Osmanabad" },
    { value: "Ottappalam", label: "Ottappalam" },
    { value: "Ozar", label: "Ozar" },
    { value: "P.n.patti", label: "P.n.patti" },
    { value: "Pachora", label: "Pachora" },
    { value: "Pachore", label: "Pachore" },
    { value: "Pacode", label: "Pacode" },
    { value: "Padmanabhapuram", label: "Padmanabhapuram" },
    { value: "Padra", label: "Padra" },
    { value: "Padrauna", label: "Padrauna" },
    { value: "Paithan", label: "Paithan" },
    { value: "Pakaur", label: "Pakaur" },
    { value: "Palacole", label: "Palacole" },
    { value: "Palai", label: "Palai" },
    { value: "Palakkad", label: "Palakkad" },
    { value: "Palani", label: "Palani" },
    { value: "Palanpur", label: "Palanpur" },
    { value: "Palasa kasibugga", label: "Palasa kasibugga" },
    { value: "Palghar", label: "Palghar" },
    { value: "Pali", label: "Pali" },
    { value: "Palia kalan", label: "Palia kalan" },
    { value: "Palitana", label: "Palitana" },
    { value: "Palladam", label: "Palladam" },
    { value: "Pallapatti", label: "Pallapatti" },
    { value: "Pallikonda", label: "Pallikonda" },
    { value: "Palwal", label: "Palwal" },
    { value: "Palwancha", label: "Palwancha" },
    { value: "Panagar", label: "Panagar" },
    { value: "Panagudi", label: "Panagudi" },
    { value: "Panaji", label: "Panaji" },
    { value: "Panchkula", label: "Panchkula" },
    { value: "Panchla", label: "Panchla" },
    { value: "Pandharkaoda", label: "Pandharkaoda" },
    { value: "Pandharpur", label: "Pandharpur" },
    { value: "Pandhurna", label: "Pandhurna" },
    { value: "Pandua", label: "Pandua" },
    { value: "Panipat", label: "Panipat" },
    { value: "Panna", label: "Panna" },
    { value: "Panniyannur", label: "Panniyannur" },
    { value: "Panruti", label: "Panruti" },
    { value: "Panvel", label: "Panvel" },
    { value: "Pappinisseri", label: "Pappinisseri" },
    { value: "Paradip", label: "Paradip" },
    { value: "Paramakudi", label: "Paramakudi" },
    { value: "Parangipettai", label: "Parangipettai" },
    { value: "Parasi", label: "Parasi" },
    { value: "Paravoor", label: "Paravoor" },
    { value: "Parbhani", label: "Parbhani" },
    { value: "Pardi", label: "Pardi" },
    { value: "Parlakhemundi", label: "Parlakhemundi" },
    { value: "Parli", label: "Parli" },
    { value: "Parola", label: "Parola" },
    { value: "Partur", label: "Partur" },
    { value: "Parvathipuram", label: "Parvathipuram" },
    { value: "Pasan", label: "Pasan" },
    { value: "Paschim punropara", label: "Paschim punropara" },
    { value: "Pasighat", label: "Pasighat" },
    { value: "Patan", label: "Patan" },
    { value: "Pathanamthitta", label: "Pathanamthitta" },
    { value: "Pathankot", label: "Pathankot" },
    { value: "Pathardi", label: "Pathardi" },
    { value: "Pathri", label: "Pathri" },
    { value: "Patiala", label: "Patiala" },
    { value: "Patna", label: "Patna" },
    { value: "Patran", label: "Patran" },
    { value: "Patratu", label: "Patratu" },
    { value: "Pattamundai", label: "Pattamundai" },
    { value: "Patti", label: "Patti" },
    { value: "Pattukkottai", label: "Pattukkottai" },
    { value: "Patur", label: "Patur" },
    { value: "Pauni", label: "Pauni" },
    { value: "Pauri", label: "Pauri" },
    { value: "Pavagada", label: "Pavagada" },
    { value: "Payyannur", label: "Payyannur" },
    { value: "Pedana", label: "Pedana" },
    { value: "Peddapuram", label: "Peddapuram" },
    { value: "Pehowa", label: "Pehowa" },
    { value: "Pen", label: "Pen" },
    { value: "Perambalur", label: "Perambalur" },
    { value: "Peravurani", label: "Peravurani" },
    { value: "Peringathur", label: "Peringathur" },
    { value: "Perinthalmanna", label: "Perinthalmanna" },
    { value: "Periyakulam", label: "Periyakulam" },
    { value: "Periyasemur", label: "Periyasemur" },
    { value: "Pernampattu", label: "Pernampattu" },
    { value: "Perumbavoor", label: "Perumbavoor" },
    { value: "Petlad", label: "Petlad" },
    { value: "Phagwara", label: "Phagwara" },
    { value: "Phalodi", label: "Phalodi" },
    { value: "Phaltan", label: "Phaltan" },
    { value: "Phillaur", label: "Phillaur" },
    { value: "Phulabani", label: "Phulabani" },
    { value: "Phulera", label: "Phulera" },
    { value: "Phulpur", label: "Phulpur" },
    { value: "Phusro", label: "Phusro" },
    { value: "Pihani", label: "Pihani" },
    { value: "Pilani", label: "Pilani" },
    { value: "Pilibanga", label: "Pilibanga" },
    { value: "Pilibhit", label: "Pilibhit" },
    { value: "Pilkhuwa", label: "Pilkhuwa" },
    { value: "Pindwara", label: "Pindwara" },
    { value: "Pinjore", label: "Pinjore" },
    { value: "Pipar city", label: "Pipar city" },
    { value: "Pipariya", label: "Pipariya" },
    { value: "Piro", label: "Piro" },
    { value: "Pithampur", label: "Pithampur" },
    { value: "Pithapuram", label: "Pithapuram" },
    { value: "Pithoragarh", label: "Pithoragarh" },
    { value: "Pollachi", label: "Pollachi" },
    { value: "Polur", label: "Polur" },
    { value: "Pondicherry", label: "Pondicherry" },
    { value: "Pondur", label: "Pondur" },
    { value: "Poonch", label: "Poonch" },
    { value: "Poonamallee", label: "Poonamallee" },
    { value: "Pudukkottai", label: "Pudukkottai" },
    { value: "Pudupet", label: "Pudupet" },
    { value: "Pune", label: "Pune" },
    { value: "Puranpur", label: "Puranpur" },
    { value: "Purandar", label: "Purandar" },
    { value: "Purba medinipur", label: "Purba medinipur" },
    { value: "Purulia", label: "Purulia" },
    { value: "Pushkar", label: "Pushkar" },
    { value: "Puttaparthi", label: "Puttaparthi" },
    { value: "Puttur", label: "Puttur" },
    { value: "Qadian", label: "Qadian" },
    { value: "Quilandy", label: "Quilandy" },
    { value: "Rabkavi banhatti", label: "Rabkavi banhatti" },
    { value: "Radhanpur", label: "Radhanpur" },
    { value: "Rae bareli", label: "Rae bareli" },
    { value: "Rafiganj", label: "Rafiganj" },
    { value: "Raghogarh-vijaypur", label: "Raghogarh-vijaypur" },
    { value: "Raghunathpur", label: "Raghunathpur" },
    { value: "Rahatgarh", label: "Rahatgarh" },
    { value: "Rahuri", label: "Rahuri" },
    { value: "Raichur", label: "Raichur" },
    { value: "Raiganj", label: "Raiganj" },
    { value: "Raigarh", label: "Raigarh" },
    { value: "Raikot", label: "Raikot" },
    { value: "Raipur", label: "Raipur" },
    { value: "Rairangpur", label: "Rairangpur" },
    { value: "Raisen", label: "Raisen" },
    { value: "Raisinghnagar", label: "Raisinghnagar" },
    { value: "Rajagangapur", label: "Rajagangapur" },
    { value: "Rajahmundry", label: "Rajahmundry" },
    { value: "Rajakhera", label: "Rajakhera" },
    { value: "Rajaldesar", label: "Rajaldesar" },
    { value: "Rajam", label: "Rajam" },
    { value: "Rajampet", label: "Rajampet" },
    { value: "Rajapalayam", label: "Rajapalayam" },
    { value: "Rajauri", label: "Rajauri" },
    { value: "Rajgarh", label: "Rajgarh" },
    { value: "Rajgarh (alwar)", label: "Rajgarh (alwar)" },
    { value: "Rajgarh (churu)", label: "Rajgarh (churu)" },
    { value: "Rajgir", label: "Rajgir" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Rajnandgaon", label: "Rajnandgaon" },
    { value: "Rajpipla", label: "Rajpipla" },
    { value: "Rajpura", label: "Rajpura" },
    { value: "Rajsamand", label: "Rajsamand" },
    { value: "Rajula", label: "Rajula" },
    { value: "Rajura", label: "Rajura" },
    { value: "Ramachandrapuram", label: "Ramachandrapuram" },
    { value: "Ramagundam", label: "Ramagundam" },
    { value: "Ramanagaram", label: "Ramanagaram" },
    { value: "Ramanathapuram", label: "Ramanathapuram" },
    { value: "Ramdurg", label: "Ramdurg" },
    { value: "Rameshwaram", label: "Rameshwaram" },
    { value: "Ramganj mandi", label: "Ramganj mandi" },
    { value: "Ramnagar", label: "Ramnagar" },
    { value: "Ramngarh", label: "Ramngarh" },
    { value: "Rampur", label: "Rampur" },
    { value: "Rampur maniharan", label: "Rampur maniharan" },
    { value: "Rampura phul", label: "Rampura phul" },
    { value: "Rampurhat", label: "Rampurhat" },
    { value: "Ramtek", label: "Ramtek" },
    { value: "Ranaghat", label: "Ranaghat" },
    { value: "Ranavav", label: "Ranavav" },
    { value: "Ranchi", label: "Ranchi" },
    { value: "Rangia", label: "Rangia" },
    { value: "Rania", label: "Rania" },
    { value: "Ranibennur", label: "Ranibennur" },
    { value: "Rapar", label: "Rapar" },
    { value: "Rasipuram", label: "Rasipuram" },
    { value: "Rasra", label: "Rasra" },
    { value: "Ratangarh", label: "Ratangarh" },
    { value: "Rath", label: "Rath" },
    { value: "Ratia", label: "Ratia" },
    { value: "Ratlam", label: "Ratlam" },
    { value: "Ratnagiri", label: "Ratnagiri" },
    { value: "Rau", label: "Rau" },
    { value: "Raurkela", label: "Raurkela" },
    { value: "Raver", label: "Raver" },
    { value: "Rawatbhata", label: "Rawatbhata" },
    { value: "Rawatsar", label: "Rawatsar" },
    { value: "Raxaul bazar", label: "Raxaul bazar" },
    { value: "Rayachoti", label: "Rayachoti" },
    { value: "Rayadurg", label: "Rayadurg" },
    { value: "Rayagada", label: "Rayagada" },
    { value: "Reengus", label: "Reengus" },
    { value: "Rehli", label: "Rehli" },
    { value: "Renigunta", label: "Renigunta" },
    { value: "Renukoot", label: "Renukoot" },
    { value: "Reoti", label: "Reoti" },
    { value: "Repalle", label: "Repalle" },
    { value: "Revelganj", label: "Revelganj" },
    { value: "Rewa", label: "Rewa" },
    { value: "Rewari", label: "Rewari" },
    { value: "Rishikesh", label: "Rishikesh" },
    { value: "Risod", label: "Risod" },
    { value: "Robertsganj", label: "Robertsganj" },
    { value: "Robertson pet", label: "Robertson pet" },
    { value: "Rohtak", label: "Rohtak" },
    { value: "Ron", label: "Ron" },
    { value: "Roorkee", label: "Roorkee" },
    { value: "Rosera", label: "Rosera" },
    { value: "Rudauli", label: "Rudauli" },
    { value: "Rudrapur", label: "Rudrapur" },
    { value: "Rupnagar", label: "Rupnagar" },
    { value: "Sabalgarh", label: "Sabalgarh" },
    { value: "Sadabad", label: "Sadabad" },
    { value: "Sadalgi", label: "Sadalgi" },
    { value: "Sadasivpet", label: "Sadasivpet" },
    { value: "Sadri", label: "Sadri" },
    { value: "Sadulshahar", label: "Sadulshahar" },
    { value: "Safidon", label: "Safidon" },
    { value: "Safipur", label: "Safipur" },
    { value: "Sagar", label: "Sagar" },
    { value: "Sagwara", label: "Sagwara" },
    { value: "Saharanpur", label: "Saharanpur" },
    { value: "Saharsa", label: "Saharsa" },
    { value: "Sahaspur", label: "Sahaspur" },
    { value: "Sahaswan", label: "Sahaswan" },
    { value: "Sahawar", label: "Sahawar" },
    { value: "Sahibganj", label: "Sahibganj" },
    { value: "Sahjanwa", label: "Sahjanwa" },
    { value: "Saidpur", label: "Saidpur" },
    { value: "Saiha", label: "Saiha" },
    { value: "Sailu", label: "Sailu" },
    { value: "Sainthia", label: "Sainthia" },
    { value: "Sakleshpur", label: "Sakleshpur" },
    { value: "Sakti", label: "Sakti" },
    { value: "Salaya", label: "Salaya" },
    { value: "Salem", label: "Salem" },
    { value: "Salur", label: "Salur" },
    { value: "Samalkha", label: "Samalkha" },
    { value: "Samalkot", label: "Samalkot" },
    { value: "Samana", label: "Samana" },
    { value: "Samastipur", label: "Samastipur" },
    { value: "Sambalpur", label: "Sambalpur" },
    { value: "Sambhal", label: "Sambhal" },
    { value: "Sambhar", label: "Sambhar" },
    { value: "Samdhan", label: "Samdhan" },
    { value: "Samthar", label: "Samthar" },
    { value: "Sanand", label: "Sanand" },
    { value: "Sanawad", label: "Sanawad" },
    { value: "Sanchore", label: "Sanchore" },
    { value: "Sandi", label: "Sandi" },
    { value: "Sandila", label: "Sandila" },
    { value: "Sandur", label: "Sandur" },
    { value: "Sangamner", label: "Sangamner" },
    { value: "Sangareddy", label: "Sangareddy" },
    { value: "Sangaria", label: "Sangaria" },
    { value: "Sangli", label: "Sangli" },
    { value: "Sangole", label: "Sangole" },
    { value: "Sangrur", label: "Sangrur" },
    { value: "Sankarankoil", label: "Sankarankoil" },
    { value: "Sankari", label: "Sankari" },
    { value: "Sankeshwar", label: "Sankeshwar" },
    { value: "Santipur", label: "Santipur" },
    { value: "Sarangpur", label: "Sarangpur" },
    { value: "Sardarshahar", label: "Sardarshahar" },
    { value: "Sardhana", label: "Sardhana" },
    { value: "Sarni", label: "Sarni" },
    { value: "Sasaram", label: "Sasaram" },
    { value: "Sasvad", label: "Sasvad" },
    { value: "Satana", label: "Satana" },
    { value: "Satara", label: "Satara" },
    { value: "Sathyamangalam", label: "Sathyamangalam" },
    { value: "Satna", label: "Satna" },
    { value: "Sattenapalle", label: "Sattenapalle" },
    { value: "Sattur", label: "Sattur" },
    { value: "Saunda", label: "Saunda" },
    { value: "Saundatti-yellamma", label: "Saundatti-yellamma" },
    { value: "Sausar", label: "Sausar" },
    { value: "Savanur", label: "Savanur" },
    { value: "Savarkundla", label: "Savarkundla" },
    { value: "Savner", label: "Savner" },
    { value: "Sawai madhopur", label: "Sawai madhopur" },
    { value: "Sawantwadi", label: "Sawantwadi" },
    { value: "Sedam", label: "Sedam" },
    { value: "Sehore", label: "Sehore" },
    { value: "Sendhwa", label: "Sendhwa" },
    { value: "Seohara", label: "Seohara" },
    { value: "Seoni", label: "Seoni" },
    { value: "Seoni-malwa", label: "Seoni-malwa" },
    { value: "Shahabad", label: "Shahabad" },
    { value: "Shahade", label: "Shahade" },
    { value: "Shahbad", label: "Shahbad" },
    { value: "Shahdol", label: "Shahdol" },
    { value: "Shahganj", label: "Shahganj" },
    { value: "Shahjahanpur", label: "Shahjahanpur" },
    { value: "Shahpur", label: "Shahpur" },
    { value: "Shahpura", label: "Shahpura" },
    { value: "Shajapur", label: "Shajapur" },
    { value: "Shamgarh", label: "Shamgarh" },
    { value: "Shamli", label: "Shamli" },
    { value: "Shamsabad", label: "Shamsabad" },
    { value: "Shegaon", label: "Shegaon" },
    { value: "Sheikhpura", label: "Sheikhpura" },
    { value: "Shendurjana", label: "Shendurjana" },
    { value: "Shenkottai", label: "Shenkottai" },
    { value: "Sheoganj", label: "Sheoganj" },
    { value: "Sheohar", label: "Sheohar" },
    { value: "Sheopur", label: "Sheopur" },
    { value: "Sherghati", label: "Sherghati" },
    { value: "Sherkot", label: "Sherkot" },
    { value: "Shiggaon", label: "Shiggaon" },
    { value: "Shikapur", label: "Shikapur" },
    { value: "Shikarpur", label: "Shikarpur" },
    { value: "Shikohabad", label: "Shikohabad" },
    { value: "Shillong", label: "Shillong" },
    { value: "Shimla", label: "Shimla" },
    { value: "Shimoga", label: "Shimoga" },
    { value: "Shirdi", label: "Shirdi" },
    { value: "Shirpur-warwade", label: "Shirpur-warwade" },
    { value: "Shirur", label: "Shirur" },
    { value: "Shishgarh", label: "Shishgarh" },
    { value: "Shivpuri", label: "Shivpuri" },
    { value: "Sholavandan", label: "Sholavandan" },
    { value: "Sholingur", label: "Sholingur" },
    { value: "Shoranur", label: "Shoranur" },
    { value: "Shorapur", label: "Shorapur" },
    { value: "Shrigonda", label: "Shrigonda" },
    { value: "Shrirampur", label: "Shrirampur" },
    { value: "Shrirangapattana", label: "Shrirangapattana" },
    { value: "Shujalpur", label: "Shujalpur" },
    { value: "Siana", label: "Siana" },
    { value: "Sibsagar", label: "Sibsagar" },
    { value: "Siddipet", label: "Siddipet" },
    { value: "Sidhi", label: "Sidhi" },
    { value: "Sidhpur", label: "Sidhpur" },
    { value: "Sidlaghatta", label: "Sidlaghatta" },
    { value: "Sihor", label: "Sihor" },
    { value: "Sihora", label: "Sihora" },
    { value: "Sikanderpur", label: "Sikanderpur" },
    { value: "Sikandra rao", label: "Sikandra rao" },
    { value: "Sikandrabad", label: "Sikandrabad" },
    { value: "Sikar", label: "Sikar" },
    { value: "Silao", label: "Silao" },
    { value: "Silapathar", label: "Silapathar" },
    { value: "Silchar", label: "Silchar" },
    { value: "Siliguri", label: "Siliguri" },
    { value: "Sillod", label: "Sillod" },
    { value: "Silvassa", label: "Silvassa" },
    { value: "Simdega", label: "Simdega" },
    { value: "Sindgi", label: "Sindgi" },
    { value: "Sindhnur", label: "Sindhnur" },
    { value: "Singapur", label: "Singapur" },
    { value: "Singrauli", label: "Singrauli" },
    { value: "Sinnar", label: "Sinnar" },
    { value: "Sira", label: "Sira" },
    { value: "Sircilla", label: "Sircilla" },
    { value: "Sirhind fatehgarh sahib", label: "Sirhind fatehgarh sahib" },
    { value: "Sirkali", label: "Sirkali" },
    { value: "Sirohi", label: "Sirohi" },
    { value: "Sironj", label: "Sironj" },
    { value: "Sirsa", label: "Sirsa" },
    { value: "Sirsaganj", label: "Sirsaganj" },
    { value: "Sirsi", label: "Sirsi" },
    { value: "Siruguppa", label: "Siruguppa" },
    { value: "Sitamarhi", label: "Sitamarhi" },
    { value: "Sitapur", label: "Sitapur" },
    { value: "Sitarganj", label: "Sitarganj" },
    { value: "Sivaganga", label: "Sivaganga" },
    { value: "Sivagiri", label: "Sivagiri" },
    { value: "Sivakasi", label: "Sivakasi" },
    { value: "Siwan", label: "Siwan" },
    { value: "Sohagpur", label: "Sohagpur" },
    { value: "Sohna", label: "Sohna" },
    { value: "Sojat", label: "Sojat" },
    { value: "Solan", label: "Solan" },
    { value: "Solapur", label: "Solapur" },
    { value: "Sonamukhi", label: "Sonamukhi" },
    { value: "Sonepur", label: "Sonepur" },
    { value: "Songadh", label: "Songadh" },
    { value: "Sonipat", label: "Sonipat" },
    { value: "Sopore", label: "Sopore" },
    { value: "Soro", label: "Soro" },
    { value: "Soron", label: "Soron" },
    { value: "Soyagaon", label: "Soyagaon" },
    { value: "Sri madhopur", label: "Sri madhopur" },
    { value: "Srikakulam", label: "Srikakulam" },
    { value: "Srikalahasti", label: "Srikalahasti" },
    { value: "Srinagar", label: "Srinagar" },
    { value: "Srinivaspur", label: "Srinivaspur" },
    { value: "Srirampore", label: "Srirampore" },
    { value: "Srivilliputhur", label: "Srivilliputhur" },
    { value: "Suar", label: "Suar" },
    { value: "Sugauli", label: "Sugauli" },
    { value: "Sujangarh", label: "Sujangarh" },
    { value: "Sujanpur", label: "Sujanpur" },
    { value: "Sultanganj", label: "Sultanganj" },
    { value: "Sultanpur", label: "Sultanpur" },
    { value: "Sumerpur", label: "Sumerpur" },
    { value: "Sunabeda", label: "Sunabeda" },
    { value: "Sunam", label: "Sunam" },
    { value: "Sundargarh", label: "Sundargarh" },
    { value: "Sundarnagar", label: "Sundarnagar" },
    { value: "Supaul", label: "Supaul" },
    { value: "Surandai", label: "Surandai" },
    { value: "Surat", label: "Surat" },
    { value: "Suratgarh", label: "Suratgarh" },
    { value: "Suri", label: "Suri" },
    { value: "Suriyampalayam", label: "Suriyampalayam" },
    { value: "Suryapet", label: "Suryapet" },
    { value: "Tadepalligudem", label: "Tadepalligudem" },
    { value: "Tadpatri", label: "Tadpatri" },
    { value: "Taki", label: "Taki" },
    { value: "Talaja", label: "Talaja" },
    { value: "Talcher", label: "Talcher" },
    { value: "Talegaon dabhade", label: "Talegaon dabhade" },
    { value: "Talikota", label: "Talikota" },
    { value: "Taliparamba", label: "Taliparamba" },
    { value: "Talode", label: "Talode" },
    { value: "Talwara", label: "Talwara" },
    { value: "Tamluk", label: "Tamluk" },
    { value: "Tanda", label: "Tanda" },
    { value: "Tandur", label: "Tandur" },
    { value: "Tanuku", label: "Tanuku" },
    { value: "Tarakeswar", label: "Tarakeswar" },
    { value: "Tarana", label: "Tarana" },
    { value: "Taranagar", label: "Taranagar" },
    { value: "Taraori", label: "Taraori" },
    { value: "Tarikere", label: "Tarikere" },
    { value: "Tarn taran", label: "Tarn taran" },
    { value: "Tasgaon", label: "Tasgaon" },
    { value: "Tehri", label: "Tehri" },
    { value: "Tekkalakota", label: "Tekkalakota" },
    { value: "Tenali", label: "Tenali" },
    { value: "Tenkasi", label: "Tenkasi" },
    { value: "Tenu dam-cum- kathhara", label: "Tenu dam-cum- kathhara" },
    { value: "Terdal", label: "Terdal" },
    { value: "Tetri bazar", label: "Tetri bazar" },
    { value: "Tezpur", label: "Tezpur" },
    { value: "Thakurdwara", label: "Thakurdwara" },
    { value: "Thammampatti", label: "Thammampatti" },
    { value: "Thana bhawan", label: "Thana bhawan" },
    { value: "Thanesar", label: "Thanesar" },
    { value: "Thangadh", label: "Thangadh" },
    { value: "Thanjavur", label: "Thanjavur" },
    { value: "Tharad", label: "Tharad" },
    { value: "Tharamangalam", label: "Tharamangalam" },
    { value: "Tharangambadi", label: "Tharangambadi" },
    { value: "Theni allinagaram", label: "Theni allinagaram" },
    { value: "Thirumangalam", label: "Thirumangalam" },
    { value: "Thirunindravur", label: "Thirunindravur" },
    { value: "Thiruparappu", label: "Thiruparappu" },
    { value: "Thirupuvanam", label: "Thirupuvanam" },
    { value: "Thiruthuraipoondi", label: "Thiruthuraipoondi" },
    { value: "Thiruvalla", label: "Thiruvalla" },
    { value: "Thiruvallur", label: "Thiruvallur" },
    { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
    { value: "Thiruvarur", label: "Thiruvarur" },
    { value: "Thodupuzha", label: "Thodupuzha" },
    { value: "Thoothukudi", label: "Thoothukudi" },
    { value: "Thoubal", label: "Thoubal" },
    { value: "Thrissur", label: "Thrissur" },
    { value: "Thuraiyur", label: "Thuraiyur" },
    { value: "Tikamgarh", label: "Tikamgarh" },
    { value: "Tilda newra", label: "Tilda newra" },
    { value: "Tilhar", label: "Tilhar" },
    { value: "Tindivanam", label: "Tindivanam" },
    { value: "Tinsukia", label: "Tinsukia" },
    { value: "Tiptur", label: "Tiptur" },
    { value: "Tirora", label: "Tirora" },
    { value: "Tiruchendur", label: "Tiruchendur" },
    { value: "Tiruchengode", label: "Tiruchengode" },
    { value: "Tiruchirappalli", label: "Tiruchirappalli" },
    { value: "Tirukalukundram", label: "Tirukalukundram" },
    { value: "Tirukkoyilur", label: "Tirukkoyilur" },
    { value: "Tirunelveli", label: "Tirunelveli" },
    { value: "Tirupathur", label: "Tirupathur" },
    { value: "Tirupati", label: "Tirupati" },
    { value: "Tiruppur", label: "Tiruppur" },
    { value: "Tirur", label: "Tirur" },
    { value: "Tiruttani", label: "Tiruttani" },
    { value: "Tiruvannamalai", label: "Tiruvannamalai" },
    { value: "Tiruvethipuram", label: "Tiruvethipuram" },
    { value: "Tirwaganj", label: "Tirwaganj" },
    { value: "Titlagarh", label: "Titlagarh" },
    { value: "Tittakudi", label: "Tittakudi" },
    { value: "Todabhim", label: "Todabhim" },
    { value: "Todaraisingh", label: "Todaraisingh" },
    { value: "Tohana", label: "Tohana" },
    { value: "Tonk", label: "Tonk" },
    { value: "Tuensang", label: "Tuensang" },
    { value: "Tuljapur", label: "Tuljapur" },
    { value: "Tulsipur", label: "Tulsipur" },
    { value: "Tumkur", label: "Tumkur" },
    { value: "Tumsar", label: "Tumsar" },
    { value: "Tundla", label: "Tundla" },
    { value: "Tuni", label: "Tuni" },
    { value: "Tura", label: "Tura" },

    { value: "Uchgaon", label: "Uchgaon" },
    { value: "Udaipur", label: "Udaipur" },
    { value: "Udaipur", label: "Udaipur" },
    { value: "Udaipurwati", label: "Udaipurwati" },
    { value: "Udgir", label: "Udgir" },
    { value: "Udhagamandalam", label: "Udhagamandalam" },
    { value: "Udhampur", label: "Udhampur" },
    { value: "Udumalaipettai", label: "Udumalaipettai" },
    { value: "Udupi", label: "Udupi" },
    { value: "Ujhani", label: "Ujhani" },
    { value: "Ujjain", label: "Ujjain" },
    { value: "Umarga", label: "Umarga" },
    { value: "Umaria", label: "Umaria" },
    { value: "Umarkhed", label: "Umarkhed" },
    { value: "Umarkote", label: "Umarkote" },
    { value: "Umbergaon", label: "Umbergaon" },
    { value: "Umred", label: "Umred" },
    { value: "Umreth", label: "Umreth" },
    { value: "Una", label: "Una" },
    { value: "Unjha", label: "Unjha" },
    { value: "Unnamalaikadai", label: "Unnamalaikadai" },
    { value: "Unnao", label: "Unnao" },
    { value: "Upleta", label: "Upleta" },
    { value: "Uran", label: "Uran" },
    { value: "Uran islampur", label: "Uran islampur" },
    { value: "Uravakonda", label: "Uravakonda" },
    { value: "Urmar tanda", label: "Urmar tanda" },
    { value: "Usilampatti", label: "Usilampatti" },
    { value: "Uthamapalayam", label: "Uthamapalayam" },
    { value: "Uthiramerur", label: "Uthiramerur" },
    { value: "Utraula", label: "Utraula" },
    { value: "Vadakara", label: "Vadakara" },
    { value: "Vadakkuvalliyur", label: "Vadakkuvalliyur" },
    { value: "Vadalur", label: "Vadalur" },
    { value: "Vadgaon kasba", label: "Vadgaon kasba" },
    { value: "Vadipatti", label: "Vadipatti" },
    { value: "Vadnagar", label: "Vadnagar" },
    { value: "Vadodara", label: "Vadodara" },
    { value: "Vaijapur", label: "Vaijapur" },
    { value: "Vaikom", label: "Vaikom" },
    { value: "Valparai", label: "Valparai" },
    { value: "Valsad", label: "Valsad" },
    { value: "Vandavasi", label: "Vandavasi" },
    { value: "Vaniyambadi", label: "Vaniyambadi" },
    { value: "Vapi", label: "Vapi" },
    { value: "Vapi", label: "Vapi" }, // Duplicate
    { value: "Varanasi", label: "Varanasi" },
    { value: "Varkala", label: "Varkala" },
    { value: "Vasai", label: "Vasai" },
    { value: "Vedaranyam", label: "Vedaranyam" },
    { value: "Vellakoil", label: "Vellakoil" },
    { value: "Vellore", label: "Vellore" },
    { value: "Venkatagiri", label: "Venkatagiri" },
    { value: "Veraval", label: "Veraval" },
    { value: "Vicarabad", label: "Vicarabad" },
    { value: "Vidisha", label: "Vidisha" },
    { value: "Vijainagar", label: "Vijainagar" },
    { value: "Vijapur", label: "Vijapur" },
    { value: "Vijayapura", label: "Vijayapura" },
    { value: "Vijayawada", label: "Vijayawada" },
    { value: "Vikramasingapuram", label: "Vikramasingapuram" },
    { value: "Viluppuram", label: "Viluppuram" },
    { value: "Vinukonda", label: "Vinukonda" },
    { value: "Viramgam", label: "Viramgam" },
    { value: "Virar", label: "Virar" },
    { value: "Virudhachalam", label: "Virudhachalam" },
    { value: "Virudhunagar", label: "Virudhunagar" },
    { value: "Visakhapatnam", label: "Visakhapatnam" },
    { value: "Visnagar", label: "Visnagar" },
    { value: "Viswanatham", label: "Viswanatham" },
    { value: "Vita", label: "Vita" },
    { value: "Vizianagaram", label: "Vizianagaram" },
    { value: "Vrindavan", label: "Vrindavan" },
    { value: "Vyara", label: "Vyara" },
    { value: "Wadgaon road", label: "Wadgaon road" },
    { value: "Wadhwan", label: "Wadhwan" },
    { value: "Wadi", label: "Wadi" },
    { value: "Wai", label: "Wai" },
    { value: "Wanaparthy", label: "Wanaparthy" },
    { value: "Wani", label: "Wani" },
    { value: "Wankaner", label: "Wankaner" },
    { value: "Wara seoni", label: "Wara seoni" },
    { value: "Warangal", label: "Warangal" },
    { value: "Wardha", label: "Wardha" },
    { value: "Warhapur", label: "Warhapur" },
    { value: "Warisaliganj", label: "Warisaliganj" },
    { value: "Warora", label: "Warora" },
    { value: "Warud", label: "Warud" },
    { value: "Washim", label: "Washim" },
    { value: "Wokha", label: "Wokha" },
    { value: "Yadgir", label: "Yadgir" },
    { value: "Yamunanagar", label: "Yamunanagar" },
    { value: "Yanam", label: "Yanam" },
    { value: "Yavatmal", label: "Yavatmal" },
    { value: "Yawal", label: "Yawal" },
    { value: "Yellandu", label: "Yellandu" },
    { value: "Yemmiganur", label: "Yemmiganur" },
    { value: "Yerraguntla", label: "Yerraguntla" },
    { value: "Yevla", label: "Yevla" },
    { value: "Zahirabad", label: "Zahirabad" },
    { value: "Zaidpur", label: "Zaidpur" },
    { value: "Zamania", label: "Zamania" },
    { value: "Zira", label: "Zira" },
    { value: "Zirakpur", label: "Zirakpur" },
    { value: "Zunheboto", label: "Zunheboto" },
  ];
  return (
    <div>
      <div class="user-area pt-100 pb-70">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-12" style={{ padding: "0px" }}>
              <div class="user-all-form">
                <div
                  class="contact-form"
                  style={{ padding: "0px", margin: "0px" }}
                >
                  <h3 class="user-title">Add Address</h3>
                  <form onSubmit={handleSubmit}>
                    <div class="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea
                            name="address"
                            value={address.address}
                            onChange={handleChange}
                            className="form-control address-textarea"
                            placeholder="Address"
                            rows="2" // Adjust the number of rows as needed
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <Select
                            options={stateOptions}
                            name="state"
                            value={
                              stateOptions.find(
                                (option) => option.value === address.state
                              ) || null
                            }
                            onChange={(selectedOption) =>
                              handleChange({
                                target: {
                                  name: "state",
                                  value: selectedOption.value,
                                },
                              })
                            }
                            placeholder="Select State"
                            styles={{
                              control: (base) => ({
                                ...base,
                                maxWidth: "510px",
                                height: "49px",
                                marginLeft: "0px",
                              }),
                              menu: (base) => ({
                                ...base,
                                backgroundColor: "white",
                                zIndex: 99999,
                              }),
                              dropdownIndicator: (base) => ({
                                ...base,
                                padding: 4,
                              }),
                              indicatorSeparator: () => ({
                                display: "none",
                              }),
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="phone"
                            value={address.phone}
                            onChange={handleChange}
                            className={`form-control ${
                              phoneError ? "is-invalid" : ""
                            }`}
                            maxLength={10}
                            minLength={10}
                            placeholder="Enter Your Phone Number"
                            required
                          />
                        </div>

                        {phoneError && (
                          <div
                            // className="invalid-feedback"
                            style={{ color: "red", marginBottom: "5px" }}
                          >
                            {phoneError}
                          </div>
                        )}
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <select
                            class="form-select"
                            value={address.addressType}
                            onChange={handleChange}
                            name="addressType"
                            aria-label="Default select example"
                            style={{
                              maxWidth: "510px",
                              marginLeft: "0px",
                              height: "49px",
                            }}
                          >
                            <option selected> Choose Address </option>
                            <option value="Home">Home </option>
                            <option value="Office">Office</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <select
                            class="form-select"
                            value={address.primary}
                            onChange={handleChange}
                            name="primary"
                            aria-label="Default select example"
                            style={{
                              maxWidth: "510px",
                              marginLeft: "0px",
                              height: "49px",
                            }}
                          >
                            <option selected>
                              {" "}
                              Is this Your Primary Address?
                            </option>
                            <option value="yes">Yes</option>
                            <option value="No">No</option>
                          </select>{" "}
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div
                          class="send-btn"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            type="submit"
                            class="default-btn border-radius-5"
                          >
                            Add Address
                            <span></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetAddress />
    </div>
  );
}

export default AddressForm;
