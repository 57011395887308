import React, { useState, useEffect } from "react";
import moment from "moment";
import { getprofiles, globalLoderAction } from "../Redux/Action";
import { ImSwitch } from "react-icons/im";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import Address from "./Address.js";
import Orders from "../Pages/Order/Order.js";
import ListReviews from "../Pages/ListReview";
import WishList from "../Pages/Whishlist.js";
import { logouts } from "../Redux/Action";
// import Card from "react-bootstrap/Card";

function MyAccount() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getprofiles())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  useEffect(() => {
    // Tabs List
    const tabss = () => {
      $(".tabs li").click(function () {
        var tabId = $(this).index();
        // Hide all tab content
        $(".tabs_item").hide();
        // Show the clicked tab content
        $(".tabs_item:eq(" + tabId + ")").show();
        // Remove 'current' class from all tabs
        $(".tabs li").removeClass("current");
        // Add 'current' class to the clicked tab
        $(this).addClass("current");
      });
    };

    // Call the tabss function after the component is rendered
    tabss();
    $(".tabs_item:eq(0)").show();
    $(".tabs li:eq(0)").addClass("current");
  }, []);

  const profilelist = useSelector(({ getprofiles }) => getprofiles.payload);
  // const styles = {
  //   textAlign: "center",
  //   textTransform: "capitalize",
  //   verticalAlign: "middle",
  // };
  // const styless = {
  //   textAlign: "center",
  //   verticalAlign: "middle",
  // };
  const handleLogout = () => {
    dispatch(logouts())
      .then((r) => {
        // Handle successful logout, if needed
      })
      .catch((e) => {
        // console.log(e);
      });
    localStorage.removeItem("AuthTok");
  };
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <div class="inner-banner inner-banner-bg10">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#f290bb" }}>My Account</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>My Account</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="shop-details-tab-area  pt-100 pb-70">
        <div class="container">
          <div class="tab shop-detls-tab">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <ul className="tabs">
                  <li onClick={() => handleTabChange(0)}>Personal Detail</li>
                  <li onClick={() => handleTabChange(1)}>Address</li>
                  <li onClick={() => handleTabChange(2)}>Wishlists</li>
                  <li onClick={() => handleTabChange(3)}>Orders</li>
                  <li onClick={() => handleTabChange(4)}>Reviews</li>
                </ul>
              </div>
            </div>

            <div class="col-lg-12 col-md-12" style={{ padding: "0px" }}>
              <div class="tab_content current active">
                <div
                  className={`tabs_item ${activeTab === 0 ? "current" : ""}`}
                >
                  <div
                    class="shop-detls-tab-content"
                    style={{ padding: "0px" }}
                  >
                    <div class="container" style={{ padding: "0px" }}>
                      <div
                        class="user-all-form user-area pt-100 pb-70"
                        style={{ paddingLeft: "0px" }}
                      >
                        <div class="contact-forms">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <img
                                src={profilelist?.profilePic}
                                alt="profile_pic"
                                style={{ borderRadius: "50%" }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div>
                                {" "}
                                <span className="badge bg-success me-2">
                                  <Link
                                    to={"/updateprofile/" + profilelist?.id}
                                    style={{ color: "white" }}
                                  >
                                    Edit
                                  </Link>
                                </span>
                              </div>
                              <div>
                                {" "}
                                <a
                                  href="/"
                                  onClick={handleLogout}
                                  style={{
                                    color: "black",
                                    backgroundColor: "#eaeee6",
                                    padding: "3px 5px",
                                    borderRadius: "4px",
                                    fontSize: "13px",
                                  }}
                                >
                                  <ImSwitch /> Logout
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-5">
                            <div class="col-lg-6">
                              <h5 className="name">User name :</h5>
                            </div>{" "}
                            <div class="col-lg-6">
                              <p style={{ textTransform: "capitalize" }}>
                                {profilelist?.userName}
                              </p>
                            </div>
                            <div class="col-lg-6">
                              <h5 className="name">Email :</h5>{" "}
                            </div>
                            <div class="col-lg-6">
                              <p> {profilelist?.email}</p>
                            </div>
                            {/* <div class="col-lg-6">
                              <h5> Phone</h5>
                            </div>
                            <div class="col-lg-6">
                              <p> {profilelist?.phone}</p>
                            </div> */}
                            {profilelist?.dob ? (
                              <>
                                <div class="col-lg-6">
                                  <h5 className="name"> Date of Birth</h5>
                                </div>
                                <div className="col-lg-6">
                                  <p style={{ textTransform: "capitalize" }}>
                                    {moment(profilelist?.dob).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p>
                                </div>
                              </>
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`tabs_item ${activeTab === 1 ? "current" : ""}`}
                >
                  <div className="shop-detls-tab-content">
                    <Address setActiveTab={setActiveTab} />
                  </div>
                </div>
                <div
                  className={`tabs_item ${activeTab === 2 ? "current" : ""}`}
                >
                  <div class="shop-detls-tab-content">
                    <WishList setActiveTab={setActiveTab} />
                  </div>
                </div>
                <div
                  className={`tabs_item ${activeTab === 3 ? "current" : ""}`}
                >
                  <div class="shop-detls-tab-content">
                    <Orders setActiveTab={setActiveTab} />
                  </div>
                </div>
                <div
                  className={`tabs_item ${activeTab === 4 ? "current" : ""}`}
                >
                  <div class="shop-detls-tab-content">
                    <ListReviews setActiveTab={setActiveTab} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="my-account-area ptb-100">
        <div class="container">
          <div class="tab account-tab">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <ul class="tabs">
                  <li>Profile</li>
                  <li>
                    {" "}
                    <a href="/addressform" style={{ color: "black" }}></a>
                  </li>{" "}
                  <li>
                    {" "}
                    <a href="/mywhishlist" style={{ color: "black" }}>
                      {" "}
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="/order" style={{ color: "black" }}>
                      {" "}
                    </a>
                  </li>
                  <li>
                    <a href="/listreview" style={{ color: "black" }}>
                      {" "}
                    </a>
                  </li>
                  <li> </li>
                </ul>
              </div>

              <div class="col-lg-8">
                <div class="user-area pt-100 pb-70">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-lg-8">
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default MyAccount;
