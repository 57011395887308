import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  getproducts,
  addwishlists,
  getwishlists,
  searchs,
  filters,
  getsingleproducts,
  addcarts,
  globalLoderAction,
} from "../Redux/Action";
import { GrFormClose } from "react-icons/gr";
import { BsFillSuitHeartFill } from "react-icons/bs";
// import Swal from "sweetalert2";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
const token = localStorage.getItem("AuthTok");

function Shop() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [change, setChange] = useState(0);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [searchProduct, setSearchProduct] = useState(null);
  const [filterProduct, setFilterProduct] = useState(null);
  const product = useSelector(({ getproducts }) => getproducts.payload);
  const sproduct = useSelector(
    ({ getsingleproducts }) => getsingleproducts.payload
  );
  const wish = useSelector(({ getwishlists }) => getwishlists.payload);
  useEffect(() => {
    dispatch({
      type: "addcartsuccess",
      data: null,
    });
    window.scrollTo(1000, 0);
  }, [dispatch]);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getwishlists())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);
  const location = useLocation();

  useEffect(() => {
    const reloadFlag = localStorage.getItem("reloadFlag");

    const savedCartData = localStorage.getItem("cartData");
    if (location.state?.fromLogin) {
      if (savedCartData) {
        localStorage.setItem("reloadFlag", "true");
        window.location.reload();
      }

      navigate(location.pathname, { replace: true, state: {} });
    }

    if (reloadFlag === "true" && savedCartData) {
      const cartData = JSON.parse(savedCartData);
      dispatch(addcarts(cartData)).then((res) => {
        setCart(res?.data?.data);
        localStorage.removeItem("reloadFlag");

        message.success({
          content: res?.data?.data,
          style: {
            marginTop: "10vh",
          },
        });
      });
      localStorage.removeItem("cartData"); // Clean up
    }

    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getproducts())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch, location, navigate]);
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchs(search))
      .then((res) => {
        setFilterProduct(res.data.data);
      })
      .then(() => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      });
  };
  const handleFilter = (e) => {
    const fromValue = parseFloat(from);
    const toValue = parseFloat(to);
    e.preventDefault();
    if (!isNaN(fromValue) && !isNaN(toValue)) {
      const newFilter = [fromValue, toValue];
      setFilter(newFilter);
      dispatch(filters(newFilter))
        .then((res) => {
          setFilterProduct(res.data.data);
          setLoad(false);
        })
        .catch(() => {
          console.log("Waiting For Network");
        });
    } else {
      console.log("Invalid input. Please enter valid numbers for from and to.");
    }
  };

  const [quantity, setQuantity] = useState(1);
  const [changeVariant, setChangeVariant] = useState(0);

  const SingleProduct = (proId) => {
    setLoad(true);
    dispatch(getsingleproducts({ productId: proId })).then((res) =>
      setLoad(false)
    );
  };

  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  // const [render1, setRender1] = useState(false);
  const [cart, setCart] = useState("");

  const handleClick = () => {
    if (token) {
      cartId.variantId = sproduct[changeVariant].id;
      cartId.units = quantity;
      // cartId.variantColor = JSON.parse(Product[changeVariant].variantColor)[change];
      localStorage.setItem("cartData", JSON.stringify(cartId));
      dispatch(addcarts(cartId)).then((res) => {
        setCart(res.data.data);
        message.success({
          content: res?.data?.data,
          style: {
            marginTop: "10vh",
          },
        });
      });
    } else {
      navigate("/login", { state: { from: window.location.pathname } });
    }
  };
  // if (sproduct) {
  // }
  return (
    <>
      <div class="inner-banner inner-banner-bg11">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#f290bb" }}>Shop</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>Shop</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="shop-area pt-100 pb-70">
        <div class="container">
          <div class="product-topper mb-45">
            <div class="row align-items-center">
              <div class="col-lg-3">
                {" "}
                <div className="view-more mb-30 mt-5">
                  <a href="/shop" className="default-btn border-radius-5">
                    Clear Filter
                  </a>
                </div>
              </div>
              <div class="col-lg-3"></div>{" "}
              <div class="col-lg-3 col-md-6">
                <div class="product-topper-search-widget">
                  <form class="search-form" onSubmit={handleSearch}>
                    <input
                      type="search"
                      name="search"
                      onChange={handleChange}
                      required
                      class="form-control"
                      placeholder="Search..."
                    />
                    <button type="submit">
                      <i class="bx bx-search"></i>
                    </button>
                  </form>
                </div>
              </div>{" "}
              <div class="col-lg-3 col-md-6">
                <form onSubmit={handleFilter}>
                  <div class="product-list">
                    {/* <select
                    class="form-select"
                    aria-marginTop:"-10px"="Default select example"
                  >
                    <option selected>Default Price</option>
                    <option value="1">Price High To Low</option>
                    <option value="2">Price Low To High</option>
                  </select>
                  <i class="bx bx-chevron-down"></i> */}
                    <span className="filt" style={{ color: "#f290bb" }}>
                      Filter by Price
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "5px",
                      }}
                    >
                      <div>
                        <input
                          type="text"
                          name="from"
                          class="form-select"
                          required
                          onChange={(e) => setFrom(e.target.value)}
                          placeholder="Min"
                          style={{ padding: "0px 20px" }}
                        />{" "}
                      </div>
                      <div>
                        <input
                          type="text"
                          name="to"
                          class="form-select"
                          required
                          onChange={(e) => setTo(e.target.value)}
                          placeholder="Max"
                          style={{ padding: "0px 20px" }}
                        />
                      </div>
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "#f290bb",
                          color: "white",
                          borderRadius: "5px",
                          border: "none",
                          padding: "0 20px",
                        }}
                      >
                        Go
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {filterProduct || searchProduct !== null ? (
            <div class="row justify-content-center">
              {Array.isArray(filterProduct) && filterProduct.length !== 0 ? (
                filterProduct.map((x) => (
                  <div class="col-6 col-lg-3 col-md-6">
                    <div class="top-branded-card mb-30">
                      <div class="top-branded-img">
                        <Link
                          to={"/ProductDetails/" + x.id}
                          style={{ textTransform: "capitalize" }}
                        >
                          <img
                            src={process.env.REACT_APP_URL + x.productImage}
                            alt="Top Branded"
                            // style={{ height: "350px", objectFit: "cover" }}
                            style={{
                              width: "100%",
                              maxWidth: "250px",
                              height: "auto",
                              aspectRatio: "1/1",
                              objectFit: "cover",
                            }}
                          />
                        </Link>
                        <ul class="top-branded-action">
                          <li>
                            <a
                              onClick={() => {
                                setLoad(true);
                                dispatch(
                                  addwishlists({ productId: x.id })
                                ).then((res) => {
                                  message.success({
                                    content: res?.data?.data,
                                    style: {
                                      marginTop: "10vh",
                                    },
                                  });
                                  setLoad(false);
                                });
                              }}
                            >
                              {Array.isArray(wish?.data) &&
                              wish?.data?.length !== 0 ? (
                                wish?.data?.some((item) => item.id === x.id) ? (
                                  <>
                                    <BsFillSuitHeartFill
                                      style={{
                                        fontSize: "14px",
                                        marginTop: "8px",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <i className="flaticon-like"></i>
                                )
                              ) : (
                                <BsFillSuitHeartFill
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "8px",
                                  }}
                                />
                              )}
                            </a>
                          </li>

                          <li>
                            <Link
                              to={"/ProductDetails/" + x.id}
                              style={{ textTransform: "capitalize" }}
                              data-bs-toggle="modal"
                            >
                              <i class="flaticon-visibility"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div class="content">
                        <h6 class="title-text">
                          <Link
                            to={"/ProductDetails/" + x.id}
                            style={{ textTransform: "capitalize" }}
                          >
                            {x.productName}
                          </Link>
                        </h6>
                        <h4>₹{x.discountPrice}</h4>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "30px",
                    marginTop: "50px",
                    marginLeft: "50px",
                  }}
                >
                  No Product Found
                </div>
              )}
            </div>
          ) : null}
          {searchProduct || filterProduct ? null : (
            <div class="row justify-content-center">
              {Array.isArray(product) && product.length !== 0 ? (
                product?.map((x) => (
                  <div class="col-6 col-lg-3 col-md-6">
                    <div class="top-branded-card mb-30">
                      <div class="top-branded-img">
                        <Link
                          to={"/ProductDetails/" + x.id}
                          style={{ textTransform: "capitalize" }}
                        >
                          <img
                            src={process.env.REACT_APP_URL + x.productImage}
                            alt="Top Branded"
                            // style={{
                            //   height: "350px",
                            //   objectFit: "cover",
                            //   borderRadius: "8px",
                            // }}
                            // style={{
                            //   width: "100%",
                            //   maxWidth: "250px",
                            //   height: "auto",
                            //   aspectRatio: "1/1",
                            //   objectFit: "cover",
                            // }}
                          />
                        </Link>
                        <ul class="top-branded-action">
                          <li>
                            <a
                              onClick={() => {
                                setLoad(true);
                                dispatch(
                                  addwishlists({ productId: x.id })
                                ).then((res) => {
                                  message.success({
                                    content: res?.data?.data,
                                    style: {
                                      marginTop: "10vh",
                                    },
                                  });
                                  setLoad(false);
                                });
                              }}
                            >
                              {Array.isArray(wish?.data) &&
                              wish?.data?.length !== 0 ? (
                                wish?.data?.some((item) => item.id === x.id) ? (
                                  <>
                                    <BsFillSuitHeartFill
                                      style={{
                                        fontSize: "14px",
                                        marginTop: "8px",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <i className="flaticon-like"></i>
                                )
                              ) : (
                                <BsFillSuitHeartFill
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "8px",
                                  }}
                                />
                              )}
                            </a>
                          </li>
                          {/* <li>
                            <a href="/cart">
                              <i class="flaticon-shopping-cart"></i>
                            </a>
                          </li> */}
                          <li>
                            <Link
                              className="product-action-btn-1"
                              title="Quick View"
                              onClick={() => SingleProduct(x.id)}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <i class="flaticon-visibility"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="content" style={{ textAlign: "center" }}>
                        <h5 className="title-text">
                          <Link
                            to={{
                              pathname: `/ProductDetails/${x.id}`,
                            }}
                            style={{ textTransform: "capitalize" }}
                          >
                            {x.productName}
                          </Link>
                        </h5>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            justifyContent: "center",
                          }}
                        >
                          <h6 style={{ textDecoration: "line-through" }}>
                            ₹{x.actualPrice}
                          </h6>
                          <h4>
                            <strong>₹{x.discountPrice}</strong>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <center className="pb-5 mb-5">
                  {/* <img
src={products}
className="mt-5 mb-5"
style={{ width: "25%" }}
/>  */}
                  <h3 className="container">No Product Found</h3>
                </center>
              )}

              {/* <div class="col-lg-12 col-md-12 text-center">
              <div class="pagination-area">
                <a href="/shop" class="prev page-numbers">
                  <i class="bx bx-chevron-left"></i>
                </a>

                <span class="page-numbers current" aria-current="page">
                  1
                </span>
                <a href="/shop" class="page-numbers">
                  2
                </a>
                <a href="/shop" class="page-numbers">
                  3
                </a>

                <a href="/shop" class="next page-numbers">
                  <i class="bx bx-chevron-right"></i>
                </a>
              </div>
            </div> */}
            </div>
          )}
        </div>
        <div
          className="modal fade quickview-modal-style"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <a className="close" data-bs-dismiss="modal" aria-label="Close">
                  {" "}
                  <GrFormClose />
                </a>
              </div>
              <div className="container p-5">
                {load ? (
                  <div
                    style={{
                      height: "500px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <div
                      className="product-details-area pb-80 pt-60 "
                      style={{ marginLeft: "-15px" }}
                    >
                      <div className="container">
                        {Array.isArray(sproduct) && sproduct.length > 0 ? (
                          <div className="row">
                            <div className="col-lg-6">
                              <div
                                className="product-details-img-wrap"
                                data-aos="fade-up"
                                data-aos-delay="200"
                              >
                                <div className="swiper-container product-details-big-img-slider-2 pd-big-img-style">
                                  <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                      <div className="easyzoom-style">
                                        <div className="easyzoom easyzoom--overlay">
                                          <a
                                            href={
                                              JSON.parse(
                                                sproduct[changeVariant]
                                                  ?.variantImage
                                              )[change]
                                            }
                                          >
                                            <img
                                              src={
                                                process.env.REACT_APP_URL +
                                                JSON.parse(
                                                  sproduct[changeVariant]
                                                    ?.variantImage
                                                )[change]
                                              }
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                        {/* <a target="_blank" className="easyzoom-pop-up img-popup" href={
                      JSON.parse(Product[changeVariant]?.variantImage)[change]
                    }>
                                                <i className="pe-7s-search"></i>
                                            </a> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div
                                className="product-details-content mt-2"
                                data-aos="fade-up"
                                data-aos-delay="400"
                              >
                                <h5 style={{ textTransform: "capitalize" }}>
                                  {sproduct[changeVariant].productName}
                                  {/* <span style={{textTransform:"capitalize"}}>
                                     ({Product[changeVariant].variantName}) 
                                </span> */}
                                </h5>
                                <div className="mt-3 mb-3">
                                  {Array.isArray(sproduct)
                                    ? sproduct?.map((data, index) => (
                                        <button
                                          onClick={() =>
                                            setChangeVariant(index)
                                          }
                                          className="btn btn-light "
                                          style={{
                                            marginRight: "10px",
                                            textTransform: "capitalize",
                                            borderRadius: "0px",
                                            textAlign: "center",
                                            padding: "0",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          {data?.variantName}
                                        </button>
                                      ))
                                    : false}
                                </div>

                                <div class="price">
                                  <span
                                    class="new-price"
                                    style={{ fontWeight: "800" }}
                                  >
                                    ₹
                                    {sproduct
                                      ? sproduct[changeVariant].discountPrice
                                      : false}
                                  </span>{" "}
                                  {/* <span class="old-price ml-2">
                                    <del>
                                      ₹
                                      {
                                        sproduct[changeVariant]?.actualPrice[
                                          change
                                        ]
                                      }
                                    </del>
                                  </span> */}
                                </div>

                                <button
                                  type="submit"
                                  class="default-btn two border-radius-5 mt-2 mb-2"
                                  onClick={handleClick}
                                >
                                  Add To Cart
                                </button>

                                <div>
                                  <span>
                                    <a
                                      href={
                                        "/productDetails/" +
                                        sproduct[changeVariant]?.productId
                                      }
                                      style={{
                                        fontSize: "14px",
                                        textDecoration: "underline",
                                        color: "green",
                                      }}
                                    >
                                      View More Details
                                    </a>
                                  </span>

                                  {/* <div className="single-product-cart btn-hover mt-5">
                                    <a  style={{fontSize:"18px",paddingTop:"10px",paddingBottom:"10px",paddingleft:"20px",}}href="#">Add to Cart</a>
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Shop;
