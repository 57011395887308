import React, { useState, useEffect } from "react";
import logo from "../assets/image/logo.svg";
import fssai from "../assets/image/FSSAI_logo.svg";
const Token = localStorage.getItem("AuthTok");

function Footer() {
  const [showScrollButton, setShowScrollButton] = useState(false);
  let date = new Date();
  let year = date.getFullYear();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <footer className="footer-area">
        <div className="container-fluid pt-100 pb-70">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="/">
                    <img src={logo} alt="Images" height={50} />
                  </a>
                </div>

                <p>
                  Our artisans handcraft each product using traditional
                  techniques and sustainable ingredients. These creations
                  celebrate your unique radiance and connect you with nature’s
                  purity, enhancing your natural beauty with every use.
                </p>
                {/* <div>
                  <img src={fssai} alt="FSSAI" height={25} />
                </div>
                <div className="mt-2">
                  <p>Lic.No. 12423999001843</p>{" "}
                </div> */}
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="footer-widget ps-5">
                <h3>Social Links</h3>
                <ul className="social-link">
                  <li>
                    <a
                      href="https://www.facebook.com/beautytales1?mibextid=JRoKGi"
                      target="_blank"
                    >
                      <i className="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/beautytales_organic?igsh=MWFvam5wdGxucDg5cw%3D%3D&utm_source=qr"
                      target="_blank"
                    >
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/beautytales/"
                      target="_blank"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wa.me/+916379060691?text=Hi%20Savitha%2C%20I%20am%20eager%20to%20buy%20quality%20products%20from%20Beauty%20tales"
                      target="_blank"
                    >
                      <i className="bx bxl-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="footer-widget ps-5">
                <h3>Useful Links</h3>
                <ul className="footer-list">
                  <li>
                    <a href="/faq">FAQ's</a>
                  </li>
                  <li>
                    <a href="/privacypolicy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/termsandconditions">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="/refund">Returns & Refunds</a>
                  </li>{" "}
                  <li>
                    <a href="/shippingdelivery">Shipping & Delivery</a>
                  </li>{" "}
                  {Token ? (
                    <li>
                      <a href="/order">Track Your Orders</a>
                    </li>
                  ) : (
                    false
                  )}
                  {/* {Token ? <li>
                    <a href="/review" >
                    Order Review
                    </a>
                  </li> :false} */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <div className="copy-right-text">
              <p>
                Copyright © {year}{" "}
                <a href="/" target="_blank">
                  Beauty Tales.
                </a>
              </p>{" "}
              <p>
                Designed by{" "}
                <strong style={{ color: "#F290BB" }}>
                  <a href="https://nutz.in" target="_blank">
                    Nutz
                  </a>
                </strong>{" "}
              </p>
            </div>
          </div>
        </div>

        {/* <div className="footer-shape">
          <div className="shape1">
            <img src="assets/images/footer/footer-shape-1.png" alt="Footer" />
          </div>
          <div className="shape2">
            <img src="assets/images/footer/footer-shape-2.png" alt="Footer" />
          </div>
          <div className="shape3">
            <img src="assets/images/footer/footer-shape-3.png" alt="Footer" />
          </div>
          <div className="shape4">
            <img src="assets/images/footer/footer-shape-4.png" alt="Footer" />
          </div>
        </div> */}
      </footer>{" "}
      {showScrollButton && (
        <div
          className={`go-top ${showScrollButton ? "active" : ""}`}
          onClick={scrollToTop}
        >
          <i className="bx bx-chevron-up"></i>
        </div>
      )}
    </>
  );
}
export default Footer;
