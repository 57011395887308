import React, { useState, useEffect } from "react";
import { globalLoderAction } from "../Redux/Action";
import { useDispatch } from "react-redux";

function ShippingandDelivery() {
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="inner-banner inner-banner-bg8">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#f290bb" }}>Shipping & Delivery</h3>
            <ul>
              <li style={{ textAlign: "justify" }}>
                <a style={{ color: "#f9c113" }} href="/">
                  Home
                </a>
              </li>
              <li style={{ color: "#000" }}>Shipping & Delivery</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="privacy-policy-area pt-100 pb-70">
        <div class="container">
          <div class="section-title text-center">
            <div class="section-subtitle">
              <img src="assets/images/section-icon.png" alt="Section Images" />
              <span>Shipping & Delivery</span>
            </div>
            <h2>beautytales Shipping & Delivery Policy</h2>
          </div>
          <div class="row pt-45">
            <div class="col-lg-12">
              <div class="single-content">
                <h3>Shipping Policy</h3>
                <h6>Processing Time</h6>
                <ul>
                  <li style={{ textAlign: "justify" }}>
                    Orders are processed within 2-3 business days.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    {" "}
                    Orders placed on weekends or holidays will be processed on
                    the next business day.
                  </li>
                </ul>
                <h6>Shipping Methods and Costs</h6>
                <ul>
                  <li style={{ textAlign: "justify" }}>
                    Standard shipping is available for all orders.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    Shipping costs are calculated at checkout based on your
                    location and the weight of your order.
                  </li>
                </ul>
              </div>
              <div class="single-content">
                <h3>Delivery Policy</h3>
                <h6>Estimated Delivery Times</h6>
                <ul>
                  <li style={{ textAlign: "justify" }}>
                    Domestic: 5-7 business days
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    Delivery times are estimates and may vary due to external
                    factors.
                  </li>
                </ul>
                <h6>Delivery Issues</h6>
                <ul>
                  <li style={{ textAlign: "justify" }}>
                    If your order is delayed or lost, please contact us at
                    beautytalescosmetics@gmail.com
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    We will work with the carrier to resolve any issues
                    promptly.
                  </li>
                </ul>{" "}
                <h6>Undeliverable Packages</h6>
                <ul>
                  <li style={{ textAlign: "justify" }}>
                    If a package is returned to us as undeliverable, we will
                    contact you to arrange reshipment.
                  </li>
                  <li style={{ textAlign: "justify" }}>
                    Additional shipping charges may apply for reshipping.
                  </li>
                </ul>
              </div>

              <div class="single-content">
                <h3>Contact Us</h3>
                <p style={{ textAlign: "justify" }}>
                  For any questions or concerns regarding your order, please
                  email us at <a>beautytalescosmetics@gmail.com</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ShippingandDelivery;
