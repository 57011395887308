import React, { useState, useEffect } from "react";
import { globalLoderAction } from "../Redux/Action";
import { useDispatch } from "react-redux";

function PrivacyPolicy() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="inner-banner inner-banner-bg8">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#f290bb" }}>Privacy Policy</h3>
            <ul>
              <li>
                <a style={{ color: "#f9c113" }} href="/">
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>Privacy Policy</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="privacy-policy-area pt-100 pb-70">
        <div class="container">
          <div class="section-title text-center">
            <div class="section-subtitle">
              <img src="assets/images/section-icon.png" alt="Section Images" />
              <span>Privacy Policy</span>
            </div>
            <h4>beautytales Privacy Policy</h4>
          </div>
          <div style={{ textAlign: "justify" }}>
            beautytales ensures secure transactions and the protection of your
            personal information. Please review our privacy policy periodically
            as it is subject to change without notice.
          </div>
          <div class="row pt-2">
            <div class="col-lg-12">
              <div class="single-content">
                <h5>Commitment to Privacy</h5>
                <p style={{ textAlign: "justify" }}>
                  We are committed to protecting your privacy. This policy
                  describes the types of personal information collected, how we
                  use and share it, and the measures we take to protect it. By
                  using our website, you consent to our privacy practices.
                </p>
              </div>
              <div class="single-content">
                <h5>Information Collection</h5>
                <p style={{ textAlign: "justify" }}>
                  We collect basic information to complete purchases or provide
                  refunds, such as your name, address, telephone number, date of
                  birth, email address, IP address, and browser details. We do
                  not sell or lease your personal information to third parties.
                </p>
              </div>
              <div class="single-content">
                <h5>Credit Card, Debit Card, and Banking Information</h5>
                <p style={{ textAlign: "justify" }}>
                  We do not collect or store your banking information, which is
                  directly transmitted through the payment gateway provider.
                </p>
              </div>{" "}
              <div class="single-content">
                <h5>Registration</h5>
                <p style={{ textAlign: "justify" }}>
                  To use certain features, you must register and create a member
                  account, providing information such as your name, email
                  address, and a password. Protect your password and change it
                  immediately if compromised.{" "}
                </p>
              </div>{" "}
              <div class="single-content">
                <h5>Customizing Your Experience</h5>
                <p style={{ textAlign: "justify" }}>
                  We use technologies like cookies and web beacons to collect
                  information about your visit to enhance and customize your
                  experience. You can decline cookies through your browser
                  settings, but this may limit certain website features.
                </p>
              </div>{" "}
              <div class="single-content">
                <h5>Use of Personal Information</h5>
                <p style={{ textAlign: "justify" }}>
                  We use your personal information to provide requested
                  services, resolve disputes, troubleshoot problems, promote
                  safe service, collect money, measure consumer interest, and
                  improve our offerings. We also use your information for
                  marketing purposes, with an option to opt-out.
                </p>
              </div>{" "}
              <div class="single-content">
                <h5>Marketing Communications</h5>
                <p style={{ textAlign: "justify" }}>
                  We may send you marketing emails, text messages, or make phone
                  calls with product information, tips, and promotions if you
                  provide your contact details. You can unsubscribe from these
                  communications at any time.
                </p>
              </div>{" "}
              <div class="single-content">
                <h5>Service-Related Communications</h5>
                <p style={{ textAlign: "justify" }}>
                  We may contact you with service-related announcements, respond
                  to your concerns, and provide customer service. These
                  communications are necessary for providing our services.
                </p>
              </div>{" "}
              <div class="single-content">
                <h5>Sharing of Personal Information</h5>
                <p style={{ textAlign: "justify" }}>
                  We may share personal information with our affiliates to
                  prevent identity theft, fraud, and other illegal activities.
                  We may also share information with third-party service
                  providers to perform functions on our behalf, such as order
                  processing and customer support.
                </p>
              </div>{" "}
              <div class="single-content">
                <h5>Security Precautions</h5>
                <p style={{ textAlign: "justify" }}>
                  We implement reasonable measures to protect personal
                  information. Data transmitted through our online store is
                  encrypted and secured by SSL as per international standards.
                </p>
              </div>{" "}
              <div class="single-content">
                <h5> Opt-Out Choice</h5>
                <p style={{ textAlign: "justify" }}>
                  Users can opt out of receiving non-essential communications by
                  visiting [unsubscribe link].
                </p>
              </div>{" "}
              <div class="single-content">
                <h5> Policy Changes</h5>
                <p style={{ textAlign: "justify" }}>
                  Our Privacy Policy may change from time to time. Significant
                  changes will be notified on this page or through email.
                </p>
              </div>
              <div class="single-content">
                <h5> Your Consent</h5>
                <p style={{ textAlign: "justify" }}>
                  By using the website and providing your information, you
                  consent to the collection and use of information as per this
                  Privacy Policy.
                </p>
              </div>
              <div class="single-content">
                <h5> Children</h5>
                <p style={{ textAlign: "justify" }}>
                  Our website is not designed for individuals under 18 years of
                  age. If we inadvertently receive personal information from a
                  visitor under 18, we will delete it from our records.
                </p>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PrivacyPolicy;
