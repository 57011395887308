import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getbanner,
  getWelcomeMessages,
  getproducts,
  addwishlists,
  getwishlists,
  foryous,
  hotdeals,
  getcategories,
  getsingleproducts,
  addcarts,
  globalLoderAction,
} from "../Redux/Action";
import { GrFormClose } from "react-icons/gr";
import { BsFillSuitHeartFill } from "react-icons/bs";
import image1 from "../assets/image/mock1.png";
import image2 from "../assets/image/mock2.png";
import image3 from "../assets/image/mock3.png";
import honey from "../assets/image/homee.jpg";
import block4 from "../assets/image/blog4.jpg";
import { BsCaretRightFill } from "react-icons/bs";
import { CustomModal } from "./customComponents/Modal";
import about from "../assets/image/honey.webp";
import Carousel from "react-bootstrap/Carousel";
import slide1 from "../assets/image/banner.png";
import OwlCarousel from "react-owl-carousel";
// import Swal from "sweetalert2";
import { message } from "antd";
import {
  MdOutlineLocalFlorist,
  MdOutlineSupportAgent,
  MdOutlinePets,
} from "react-icons/md";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const token = localStorage.getItem("AuthTok");

function Home() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  let navigate = useNavigate();
  const [welcomeinfo, setWelcomeinfo] = useState("");
  const banner = useSelector(({ getbanner }) => getbanner.payload);
  // const product = useSelector(({ getproducts }) => getproducts.payload);
  const category = useSelector(({ getcategories }) => getcategories.payload);
  const you = useSelector(({ foryous }) => foryous.payload);
  const hotdeal = useSelector(({ hotdeals }) => hotdeals.payload);
  // const wish = useSelector(({ addwishlists }) => addwishlists.payload);
  const sproduct = useSelector(
    ({ getsingleproducts }) => getsingleproducts.payload
  );
  // const carts = useSelector(({ addcarts }) => addcarts.payload);
  const [change, setChange] = useState(0);
  const wishlist = useSelector(({ getwishlists }) => getwishlists.payload);
  const location = useLocation();

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getwishlists())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWelcomeMessages())
      .then((res) => {
        // console.log("Response data:", res?.data?.data);
        setWelcomeinfo(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [dispatch]);
  const [modalOpen, setModalOpen] = useState(true);
  useEffect(() => {
    let modalShownStatus = sessionStorage.getItem("isModalShown");
    if (modalShownStatus) setModalOpen(false);
  }, []);
  function catshop(id) {
    navigate("/categoryshop/" + id);
  }
  useEffect(() => {
    if (location.state?.fromLogin) {
      // Remove fromLogin flag after the reload to prevent loop
      navigate(location.pathname, { replace: true, state: {} });
      window.location.reload();
    }
    dispatch(globalLoderAction("showLoader"));

    Promise.allSettled([
      dispatch(getbanner()),
      dispatch(getproducts()),
      dispatch(getcategories()),
      dispatch(hotdeals()),
      dispatch(foryous()),
    ])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [location, navigate, dispatch]);
  const [quantity, setQuantity] = useState(1);
  const [changeVariant, setChangeVariant] = useState(0);

  // console.log("id", sproduct?.[changeVariant]);

  const SingleProduct = (proId) => {
    setLoad(true);
    dispatch(getsingleproducts({ productId: proId })).then((res) =>
      setLoad(false)
    );
  };

  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  // const [render1, setRender1] = useState(false);
  const handleClick = () => {
    if (token) {
      cartId.variantId = sproduct[changeVariant].id;
      cartId.units = quantity;
      // cartId.variantColor = JSON.parse(Product[changeVariant].variantColor)[change];
      dispatch(addcarts(cartId)).then((res) => {
        message.success({
          content: res?.data?.data,
          style: {
            marginTop: "10vh",
          },
        });
      });
    } else {
      navigate("/login", {
        state: { from: window.location.pathname },
      });
    }
  };

  return (
    <>
      {/* banner modal */}
      {/* <CustomModal
        bannerImg={process.env.REACT_APP_URL + welcomeinfo?.image}
        isOpen={modalOpen}
        toggle={() => {
          sessionStorage.setItem("isModalShown", "true");
          setModalOpen((prev) => !prev);
        }}
      /> */}
      <div className="banner-area">
        <Carousel>
          {Array.isArray(banner) && banner.length !== 0 ? (
            banner?.map((B, index) => (
              // <div style={{ width: "100%", display: "flex" }}>
              <Carousel.Item key={index}>
                {/* <div style={{ width: "100%", display: "flex" }}> */}
                <img
                  className="d-block w-100 TJM-carousal"
                  src={process.env.REACT_APP_URL + B.bannerImage}
                  alt="Carousel slide"
                  style={{
                    userSelect: "none",
                  }}
                />

                {/* <Carousel.Caption
                  className="custom-carousel-caption"
                  style={{
                    backgroundColor: "#00000012",
                    width: "40%",
                    padding: "70px",
                  }}
                >
                  <h1 className="head1" style={{ color: "#05af6a" }}>
                    Love it Wild, Love it Right
                  </h1>
                  <h6 className="mt-2 head6">
                    {" "}
                    - Pure Organic Honey from Our Farm-Kept Bees
                  </h6>

                  <p className="mt-3 para">
                    Saving Honey Bees from extinction is as Sweet as Honey!
                    <br /> Have you tried tasting it? (or) Try tasting it with
                    us
                  </p>
                  <a href="/shop" class="default-btn btns border-radius-5 mb-1">
                    Buy Now
                  </a>
                </Carousel.Caption> */}
                {/* </div> */}
              </Carousel.Item>
              // </div>
            ))
          ) : (
            <Carousel.Item>
              <img src={slide1} alt="Shape" style={{ width: "100%" }} />
            </Carousel.Item>
          )}
        </Carousel>

        {/* 
        <div className="banner-shape">
          <div className="shape1">
            <img src="assets/images/home-one/shape2.png" alt="Shape" />
          </div>
          <div className="shape2">
            <img src="assets/images/home-one/shape1.png" alt="Shape" />
          </div>
        </div> */}
      </div>
      <div class="top-branded-area mt-5">
        <div class="container-fluid">
          <div class="section-title text-center mtb-45">
            <div class="section-subtitle">
              {/* <img src="assets/images/section-icon.png" alt="Section Images" /> */}
              <span className="Cat-head">Category</span>
            </div>
            {/* <h2>Distinct Honey Varieties</h2> */}
            <div className="row mt-5">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav
                autoplay={true} // Enable autoplay
                autoplayTimeout={3000}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 4,
                  },
                }}
              >
                {Array.isArray(category) && category.length !== 0 ? (
                  category?.map((x, index) => (
                    <div className="item" key={index}>
                      <div className="best-seller-card">
                        <div className="best-seller-img">
                          <img
                            src={process.env.REACT_APP_URL + x.categoryImage}
                            // src={ x.categoryImage}
                            alt="Products"
                            onClick={() => catshop(x.id)}
                            style={{
                              height: "350px",
                              width: "400px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                        </div>

                        <div className="content">
                          <Link onClick={() => catshop(x.id)}>
                            {" "}
                            <h5
                              style={{
                                textTransform: "capitalize",
                                color: "#000",
                              }}
                            >
                              {x.categoryName}
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <center className="pb-5 mb-5">
                    {/* <img
     src={products}
     className="mt-5 mb-5"
     style={{ width: "25%" }}
   />  */}
                    <h3 className="container-fluid">No Category Found</h3>
                  </center>
                )}
              </OwlCarousel>
            </div>
          </div>
        </div>{" "}
      </div>
      <div class="about-area pt-100 pb-70">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-content">
                <div class="section-title">
                  <div class="section-subtitle">
                    {/* <img
                      src="assets/images/section-icon.png"
                      alt="Section Images"
                    /> */}
                    <span>About us</span>
                  </div>
                  <h2>Why Beauty Tales?</h2>
                </div>
                <ul>
                  <li>
                    <i class="bx bx-check"></i>
                    <div class="content">
                      <h5>We use only natural, organic ingredients.</h5>
                    </div>
                  </li>
                  <li>
                    <i class="bx bx-check"></i>
                    <div class="content">
                      <h5>Each product is carefully handcrafted.</h5>
                    </div>
                  </li>
                  <li>
                    <i class="bx bx-check"></i>
                    <div class="content">
                      <h5>
                        Our products provide the purest care for your hair and
                        skin.
                      </h5>
                    </div>
                  </li>
                  <li>
                    <i class="bx bx-check"></i>
                    <div class="content">
                      <h5>We prioritize high-quality standards</h5>
                    </div>
                  </li>
                  <li>
                    <i class="bx bx-check"></i>
                    <div class="content">
                      <h5>Experience the beauty of nature with every use</h5>
                    </div>
                  </li>
                </ul>
                <a href="/about" className="default-btn border-radius-5">
                  Learn More
                </a>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="about-img product-banner-card">
                <img
                  src={block4}
                  alt="About Images"
                  style={{ borderRadius: "40px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {token ? (
        <div class="top-branded-area pb-100 top-branded-area mt-5">
          <div class="container-fluid">
            <div class="section-title text-center mb-45">
              <div class="section-subtitle">
                {/* <img
                  src="assets/images/section-icon.png"
                  alt="Section Images"
                /> */}
                {/* <span>Best Seller</span> */}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2>Featured Products</h2>
                <a href="/shop" className="default-btn border-radius-5">
                  Explore More
                </a>
              </div>
            </div>
            <div class="row">
              {Array.isArray(you) && you.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={10}
                  nav
                  autoplay={true} // Enable autoplay
                  autoplayTimeout={3000}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 2,
                    },
                    1000: {
                      items: 4,
                    },
                  }}
                >
                  {you && Array.isArray(you) && you.length > 0 ? (
                    you.map((x) => (
                      <div className="top-branded-item">
                        <div className="top-branded-img">
                          <Link to={"/ProductDetails/" + x.id}>
                            <img
                              src={process.env.REACT_APP_URL + x.productImage}
                              alt="Top Branded"
                              style={{
                                height: "400px",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                          </Link>
                          <ul className="top-branded-action">
                            <li>
                              <a
                                onClick={() => {
                                  setLoad(true);
                                  dispatch(
                                    addwishlists({ productId: x.id })
                                  ).then((res) => {
                                    message.success({
                                      content: res.data.data,
                                      style: { marginTop: "10vh" },
                                    });
                                    setLoad(false);
                                  });
                                }}
                              >
                                {Array.isArray(wishlist?.data) &&
                                wishlist?.data?.length !== 0 ? (
                                  wishlist?.data?.some(
                                    (item) => item.id === x.id
                                  ) ? (
                                    <BsFillSuitHeartFill
                                      style={{
                                        fontSize: "14px",
                                        marginTop: "8px",
                                      }}
                                    />
                                  ) : (
                                    <i className="flaticon-like"></i>
                                  )
                                ) : (
                                  <BsFillSuitHeartFill
                                    style={{
                                      fontSize: "14px",
                                      marginTop: "8px",
                                    }}
                                  />
                                )}
                              </a>
                            </li>

                            <li>
                              <Link
                                className="product-action-btn-1"
                                title="Quick View"
                                onClick={() => SingleProduct(x.id)}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <i className="flaticon-visibility"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="content">
                          <h5>
                            <Link
                              to={"/ProductDetails/" + x.id}
                              style={{
                                textTransform: "capitalize",
                                color: "#000",
                              }}
                            >
                              {x.productName}
                            </Link>
                          </h5>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              justifyContent: "center",
                            }}
                          >
                            <h6 style={{ textDecoration: "line-through" }}>
                              ₹{x.actualPrice}
                            </h6>
                            <h4>
                              <strong>₹{x.discountPrice}</strong>
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <center className="pb-5 mb-5">
                      <h3 className="container-fluid">No Product Found</h3>
                    </center>
                  )}
                </OwlCarousel>
              )}
            </div>
          </div>

          {/* <div className="best-seller-shape">
          <div className="shape1">
            <img
              src="assets/images/products/product-shape1.png"
              alt="Product Images"
            />
          </div>
          <div className="shape2">
            <img
              src="assets/images/products/product-shape2.png"
              alt="Product Images"
            />
          </div>
        </div> */}
        </div>
      ) : (
        false
      )}
      {/* <div class="product-banner-area pt-100 pb-70">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-5 col-md-5">
              <div class="product-banner-cards">
                <img src={image3} alt="Product Banner" />
                <div class="content mt-2">
                  <span>Top Collection </span>
                  <h3>Ethically Harvested Honey</h3>
                  <a href="/shop" class="default-btn border-radius-5 shoop">
                    Shop Now
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-7 col-md-7">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="product-banner-card-two">
                    <div class="content">
                      <span>New Arrivals</span>
                      <h3>Innovative & Healthy Products</h3>
                      <a href="/shop" class="default-btn border-radius-5 shoop">
                        Shop Now
                      </a>
                    </div>
                    <div class="product-banner-card-img">
                      <img src={image2} alt="Product Banner" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div
                    class="product-banner-card-three"
                    style={{ padding: "30px 60px 30px 0px" }}
                  >
                    <div class="product-banner-card-img">
                      <img src={image1} alt="Product Banner" />
                    </div>
                    <div class="content">
                      <span>Deals & Steals</span>
                      <h3>Antibiotic Free Honey</h3>
                      <a href="/shop" class="default-btn border-radius-5 shoop">
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {token ? (
        <div class="top-branded-area pb-100 top-branded-area mt-5">
          <div class="container-fluid">
            <div class="section-title text-center mb-45">
              <div class="section-subtitle">
                {/* <img
                  src="assets/images/section-icon.png"
                  alt="Section Images"
                /> */}
                {/* <span>Hot Deals</span> */}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2>Top Beautytales Products</h2>
                <a href="/shop" className="default-btn border-radius-5">
                  Explore More
                </a>
              </div>
            </div>
            <div class="row">
              {Array.isArray(hotdeal) && hotdeal.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={10}
                  nav
                  autoplay={true} // Enable autoplay
                  autoplayTimeout={3000}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 2,
                    },
                    1000: {
                      items: 4,
                    },
                  }}
                >
                  {Array.isArray(hotdeal) && hotdeal.length > 0 ? (
                    hotdeal?.map((x) => (
                      <div class="top-branded-item">
                        <div class="top-branded-img">
                          <Link to={"/ProductDetails/" + x.id}>
                            <img
                              // src={x.productImage}
                              src={process.env.REACT_APP_URL + x.productImage}
                              alt="Top Branded"
                              style={{
                                height: "400px",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                          </Link>
                          <ul class="top-branded-action">
                            <li>
                              <a
                                onClick={() => {
                                  setLoad(true);
                                  dispatch(
                                    addwishlists({ productId: x.id })
                                  ).then((res) => {
                                    message.success({
                                      content: res.data.data,
                                      style: {
                                        marginTop: "10vh",
                                      },
                                    });
                                    setLoad(false);
                                  });
                                }}
                              >
                                {Array.isArray(wishlist?.data) &&
                                wishlist?.data?.length !== 0 ? (
                                  wishlist?.data?.some(
                                    (item) => item.id === x.id
                                  ) ? (
                                    <>
                                      <BsFillSuitHeartFill
                                        style={{
                                          fontSize: "14px",
                                          marginTop: "8px",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <i className="flaticon-like"></i>
                                  )
                                ) : (
                                  <BsFillSuitHeartFill
                                    style={{
                                      fontSize: "14px",
                                      marginTop: "8px",
                                    }}
                                  />
                                )}
                              </a>
                            </li>

                            <li>
                              <Link
                                className="product-action-btn-1"
                                title="Quick View"
                                onClick={() => SingleProduct(x.id)}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <i class="flaticon-visibility"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div class="content">
                          <h5>
                            <Link
                              to={"/ProductDetails/" + x.id}
                              style={{
                                textTransform: "capitalize",
                                color: "#000",
                              }}
                            >
                              {x.productName}
                            </Link>
                          </h5>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              justifyContent: "center",
                            }}
                          >
                            <h6 style={{ textDecoration: "line-through" }}>
                              ₹{x.actualPrice}
                            </h6>
                            <h4>
                              <strong>₹{x.discountPrice}</strong>
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <center className="pb-5 mb-5">
                      <h3 className="container-fluid">No Product Found</h3>
                    </center>
                  )}
                </OwlCarousel>
              )}
            </div>
          </div>

          {/* <div className="best-seller-shape">
          <div className="shape1">
            <img
              src="assets/images/products/product-shape1.png"
              alt="Product Images"
            />
          </div>
          <div className="shape2">
            <img
              src="assets/images/products/product-shape2.png"
              alt="Product Images"
            />
          </div>
        </div> */}
        </div>
      ) : (
        false
      )}
      <div class="beauty-area pt-100 pb-70">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="beauty-img product-banner-card">
                <img
                  src={honey}
                  alt="Beauty"
                  style={{ borderRadius: "40px" }}
                />
                {/* <div className="sub-img">
                  <img
                    src={honey}
                    alt="Beauty"
                    style={{ borderRadius: "40px" }}
                  />
                </div> */}
              </div>
            </div>

            <div class="col-lg-6">
              <div class="beauty-content">
                <div class="section-title">
                  <div class="section-subtitle">
                    {/* <img
                      src="assets/images/section-icon.png"
                      alt="Section Images"
                    /> */}
                    <span>Why we</span>
                  </div>
                  <h2>Why We See Organic As Life Purposing?</h2>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} /> Organic
                    products are free from harmful chemicals, promoting overall
                    well-being.
                  </p>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} /> Using
                    natural ingredients supports sustainable practices and
                    protects the environment.
                  </p>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} /> Organic
                    care aligns with a lifestyle that values natural and
                    wholesome living.
                  </p>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} /> We believe
                    in the highest quality and purity for better health and
                    beauty.
                  </p>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} /> Embracing
                    organic care fosters a deeper connection with the earth and
                    its resources.
                  </p>
                </div>
                <a href="/shop" class="default-btn border-radius-5">
                  Shop Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="beauty-shape">
          <img src="assets/images/beauty/beauty-shape.png" alt="Beauty" />
        </div>
      </div>
      <div
        className="modal fade quickview-modal-style"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <a
                href="#"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <GrFormClose />
              </a>
            </div>

            <div className="container p-5">
              {load ? (
                <div
                  style={{
                    height: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                <div className="modal-body">
                  <div
                    className="product-details-area pb-80 pt-60 "
                    style={{ marginLeft: "-15px" }}
                  >
                    <div className="container">
                      {Array.isArray(sproduct) && sproduct.length > 0 ? (
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className="product-details-img-wrap"
                              data-aos="fade-up"
                              data-aos-delay="200"
                            >
                              <div className="swiper-container product-details-big-img-slider-2 pd-big-img-style">
                                <div className="swiper-wrapper">
                                  <div className="swiper-slide">
                                    <div className="easyzoom-style">
                                      <div className="easyzoom easyzoom--overlay">
                                        <a
                                          href={
                                            JSON.parse(
                                              sproduct[changeVariant]
                                                ?.variantImage
                                            )[change]
                                          }
                                        >
                                          <img
                                            src={
                                              process.env.REACT_APP_URL +
                                              JSON.parse(
                                                sproduct[changeVariant]
                                                  ?.variantImage
                                              )[change]
                                            }
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                      {/* <a target="_blank" className="easyzoom-pop-up img-popup" href={
                      JSON.parse(Product[changeVariant]?.variantImage)[change]
                    }>
                                                <i className="pe-7s-search"></i>
                                            </a> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="product-details-content mt-2"
                              data-aos="fade-up"
                              data-aos-delay="400"
                            >
                              <h5 style={{ textTransform: "capitalize" }}>
                                {sproduct[changeVariant].productName}
                                {/* <span style={{textTransform:"capitalize"}}>
                                     ({Product[changeVariant].variantName}) 
                                </span> */}
                              </h5>
                              <div className="mt-3 mb-3">
                                {Array.isArray(sproduct)
                                  ? sproduct?.map((data, index) => (
                                      <button
                                        onClick={() => setChangeVariant(index)}
                                        className="btn btn-light "
                                        style={{
                                          marginRight: "10px",
                                          textTransform: "capitalize",
                                          borderRadius: "0px",
                                          textAlign: "center",
                                          padding: "0",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {data?.variantName}
                                      </button>
                                    ))
                                  : false}
                              </div>

                              <div class="price">
                                <span
                                  class="new-price"
                                  style={{ fontWeight: "800" }}
                                >
                                  ₹
                                  {sproduct
                                    ? sproduct[changeVariant].discountPrice
                                    : false}
                                </span>{" "}
                              </div>

                              <button
                                type="submit"
                                class="default-btn two border-radius-5 mt-2 mb-2"
                                onClick={handleClick}
                              >
                                Add To Cart
                              </button>

                              <div>
                                <span>
                                  <a
                                    href={
                                      "/productDetails/" +
                                      sproduct[changeVariant]?.productId
                                    }
                                    style={{
                                      fontSize: "14px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    View More Details
                                  </a>
                                </span>

                                {/* <div className="single-product-cart btn-hover mt-5">
                                    <a  style={{fontSize:"18px",paddingTop:"10px",paddingBottom:"10px",paddingleft:"20px",}}href="#">Add to Cart</a>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="facility-area section-bg pt-100 pb-70">
        <div className="container">
          <div className="row justify-content-center g-4">
            <div className="col-lg-4 col-12 ">
              <div className="facility-item">
                <div className="content">
                  <MdOutlineLocalFlorist
                    className="icon"
                    style={{ color: "#F290BB", fontSize: "2rem" }}
                  />
                  <h3 s>Farm Fresh Ingredients</h3>
                </div>
                <p>
                  We typically infuse locally sourced ingredients into our
                  products to suit Indian skin types.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <div className="facility-item">
                <div className="content">
                  <MdOutlineSupportAgent
                    className="icon"
                    style={{ color: "#F290BB", fontSize: "2rem" }}
                  />
                  <h3>24 / 7 Support</h3>
                </div>
                <p>
                  Our dedicated 24X7 support team is here to assist you at any
                  time, providing prompt and reliable help to enhance your
                  customer satisfaction.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <div className="facility-item">
                <div className="content">
                  <MdOutlinePets
                    className="icon"
                    style={{ color: "#F290BB", fontSize: "2rem" }}
                  />
                  <h3>Cruelty Free</h3>
                </div>
                <p>
                  Our products are 100% cruelty-free and never tested on
                  animals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="testimonials-area testimonials-bg pb-100 ">
        <div class="container-fluid ">
          <div class="testimonials-slider owl-carousel owl-theme">
            <div class="testimonials-item pt-5">
              <div class="section-title text-center">
                <h2>Ms. Keerthi</h2>
              </div>
              <i class="bx bxs-quote-right"></i>
              <p>
                "The quality is superb, I was skeptical at first but now have
                become a regular customer. Please keep up the good work!! Should
                try at least once. Moringa Honey tastes so good and is also
                relatively lightweight than other flavors. Definitely
                recommended for its taste and medicinal properties."
              </p>
            </div>

            <div class="testimonials-item  pt-5">
              <div class="section-title text-center">
                <h2>Ms. Jeevitha</h2>
              </div>
              <i class="bx bxs-quote-right"></i>
              <p>
                "I have been using Moringa Honey for the past 6 months. It is
                pure and natural and tastes good. I am very satisfied because
                I’m giving natural honey to my baby. Their services are also
                good!"
              </p>
            </div>

            <div class="testimonials-item  pt-5">
              <div class="section-title text-center">
                <h2>Ms. Vasumathi</h2>
              </div>
              <i class="bx bxs-quote-right"></i>
              <p>
                "Honey received. Packing also good. Original taste Super 😋"
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default Home;
