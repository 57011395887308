import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/image/logo.svg";
// import { BiLogIn } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import {
  // BiLogOut,
  BiUser,
} from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import {
  getcartcounts,
  logouts,
  getprofiles,
  googlelogin,
} from "../Redux/Action";
import { message } from "antd";

const Token = localStorage.getItem("AuthTok");

function Header() {
  const [show, setShow] = useState(true);
  // const [close, setClose] = useState(false);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  // const [hov, setHov] = useState(false);
  const Count = useSelector(({ getcartcounts }) => getcartcounts.payload);
  useEffect(() => {
    dispatch(getcartcounts())
      .then((res) => {})
      .catch((err) => {
        if (err.response.data.error.message === "UnAuthorized") {
          dispatch(logouts());
          localStorage.removeItem("AuthTok");
          window.location.href = "/";
        }
      });
  }, [dispatch]);
  const location = useLocation();
  const navigate = useNavigate();
  // const showList = () => {
  //   setShow(!show);
  //   setClose(!close);
  // };
  const profilelist = useSelector(({ getprofiles }) => getprofiles.payload);
  useEffect(() => {
    if (Token) {
      dispatch(getprofiles()).then((res) => {});
    }
  }, [dispatch]);

  let name = profilelist?.userName;

  const styles = {
    textAlign: "center",
    fontWeight: "bolder",
    marginTop: "10px",
    fontSize: "22px",
    cursor: "pointer",
    "styles:hover": { textDecoration: "none" },
  };
  const responseMessage = (response) => {
    const accessToken = response.credential;
    dispatch(googlelogin({ accessToken: accessToken })).then((res) => {
      setLoad(true);

      window.location.reload();
    });
  };

  const errorMessage = (error) => {
    console.log(error);
    message.error({
      content: error,
      style: {
        marginTop: "10vh",
      },
    });
  };
  const initiateGoogleSignIn = () => {
    // Store current page URL for redirection after login
    sessionStorage.setItem("redirectUrl", window.location.pathname);
  };

  const [isActive, setIsActive] = useState(false);
  const [isAcActive, setIsAcActive] = useState(false);

  const activeLinkStyle = {
    display: "flex",
    // color: isActive ? "green" : "white",
    color: "white",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    background: "green",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "-10px",
    marginTop: "-5px",
    fontSize: "10px",
    // Set your desired color for the active link
    // Add any additional styles you need
  };

  const handleLinkClick = () => {
    setIsActive(!isActive);
  };

  const handleAcLinkClick = () => {
    setIsAcActive(!isActive);
  };

  return (
    <>
      <header class="top-header">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-9">
              <div class="header-right">
                <ul>
                  <li>
                    <i class="bx bx-current-location"></i>
                    9, Thiru. Vee. Ka. Nagar, 5th street, Dharapuram Main Rd,
                    Perichipalayam, Tiruppur, Tamil Nadu 641604
                  </li>

                  <li>
                    <i class="bx bxs-phone"></i>
                    <a href="tel:+916379060691">+916379060691</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="header-left float-end">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/beautytales1?mibextid=JRoKGi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/beautytales_organic?igsh=MWFvam5wdGxucDg5cw%3D%3D&utm_source=qr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="bx bxl-instagram "></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/beautytales/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="bx bxl-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wa.me/+916379060691?text=Hi%20Savitha%2C%20I%20am%20eager%20to%20buy%20quality%20products%20from%20Beauty%20tales"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="bx bxl-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        class="modal fade fade-scale topheader"
        id="topheader"
        tabIndex="-1"
        role="dialog"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal">
              <i class="bx bx-x"></i>
            </button>
            <div class="modal-body">
              <div class="modal-social-link">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/beautytales1?mibextid=JRoKGi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/beautytales_organic?igsh=MWFvam5wdGxucDg5cw%3D%3D&utm_source=qr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="bx bxl-instagram "></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/beautytales/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="bx bxl-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wa.me/+916379060691?text=Hi%20Savitha%2C%20I%20am%20eager%20to%20buy%20quality%20products%20from%20Beauty%20tales"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="bx bxl-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="modal-list">
                <ul>
                  <li>
                    <i class="bx bx-location-plus"></i>
                    115/3, R.S Road, Near Royal Theatre, Pallipalyam, Erode-638
                    006, Namakkkal District.
                  </li>

                  <li>
                    <i class="bx bxs-phone"></i>
                    <a href="tel:+917397425248">+9173974 25248</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="navbar-area">
        <div class="mobile-responsive-nav">
          <div class="container">
            <div
              class="mobile-responsive-menu"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div class="logo">
                <a href="/">
                  <img src={logo} alt="logo" height={30} />
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "30px",
                }}
              >
                <div class="optional-item">
                  {Token ? (
                    <NavLink to="/cart" class="nav-link">
                      {/* <a class="optional-item-cart">
                        <i class="bx bx-cart-alt"></i>
                        {Count?.count > 0 ? (
                          <span>{Count.count}</span>
                        ) : (
                          false
                        )}{" "}
                      </a> */}
                      {/* <div class="optional-item-cart"> */}
                      <div
                        onClick={() => handleLinkClick()}
                        style={{ display: "flex", width: "100%" }}
                      >
                        <AiOutlineShoppingCart
                          style={{
                            fontSize: "23px",
                            color: "#000",
                            marginLeft: "-10px",
                          }}
                        />
                        {Count?.count > 0 ? (
                          <span style={activeLinkStyle}>{Count.count}</span>
                        ) : (
                          false
                        )}
                      </div>{" "}
                      {/* </div> */}
                    </NavLink>
                  ) : (
                    <NavLink to="/login" class="nav-link">
                      {/* <a class="optional-item-cart">
                        <i class="bx bx-cart-alt"></i>
                        {Count?.count > 0 ? (
                          <span>{Count.count}</span>
                        ) : (
                          false
                        )}{" "}
                      </a> */}
                      {/* <div class="optional-item-cart"> */}
                      <div
                        onClick={() => handleLinkClick()}
                        style={{ display: "flex", width: "100%" }}
                      >
                        <AiOutlineShoppingCart
                          style={{
                            fontSize: "23px",
                            color: "#000",
                            marginLeft: "-10px",
                          }}
                        />
                        {Count?.count > 0 ? (
                          <span style={activeLinkStyle}>{Count.count}</span>
                        ) : (
                          false
                        )}
                      </div>{" "}
                      {/* </div> */}
                    </NavLink>
                  )}
                </div>{" "}
                <div>
                  {" "}
                  <span
                    style={{ float: "right" }}
                    onClick={() => setShow(!show)}
                  >
                    {" "}
                    <span className="hamburger" style={{ cursor: "pointer" }}>
                      {show ? (
                        <GiHamburgerMenu style={{ fontSize: "30px" }} />
                      ) : (
                        <CgClose style={{ fontSize: "30px" }} />
                      )}
                    </span>
                  </span>
                </div>
              </div>
            </div>{" "}
            {show ? (
              false
            ) : (
              <div className="responsive">
                <ul className="p-0 mt-2 mb-3" style={{ listStyleType: "none" }}>
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <NavLink to="/" style={{ color: "black" }}>
                      {" "}
                      Home{" "}
                    </NavLink>
                  </li>
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <NavLink to="/shop" style={{ color: "black" }}>
                      Shop{" "}
                    </NavLink>
                  </li>
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <NavLink to="/categories" style={{ color: "black" }}>
                      {" "}
                      Categories
                    </NavLink>{" "}
                  </li>{" "}
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <NavLink to="/blogs" style={{ color: "black" }}>
                      {" "}
                      Blog{" "}
                    </NavLink>
                  </li>
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <NavLink to="/About" style={{ color: "black" }}>
                      {" "}
                      About{" "}
                    </NavLink>
                  </li>
                  {Token ? (
                    <li
                      className="hover"
                      onClick={() => setShow(true)}
                      style={styles}
                    >
                      <NavLink to="/profile" style={{ color: "black" }}>
                        {" "}
                        Profile
                      </NavLink>{" "}
                    </li>
                  ) : (
                    false
                  )}
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <Link to="/Contact" style={{ color: "black" }}>
                      {" "}
                      Contact
                    </Link>{" "}
                  </li>{" "}
                  {Token ? (
                    <li
                      className="hover"
                      onClick={() => setShow(true)}
                      style={styles}
                    >
                      <NavLink to="/cart" style={{ color: "black" }}>
                        {" "}
                        Cart{" "}
                      </NavLink>
                    </li>
                  ) : (
                    false
                  )}
                  {Token ? (
                    false
                  ) : (
                    <li
                      className="hover"
                      onClick={() => setShow(true)}
                      style={styles}
                    >
                      <GoogleOAuthProvider
                        clientId={process.env.REACT_APP_CLIENT_ID}
                      >
                        <GoogleLogin
                          onSuccess={responseMessage}
                          onError={errorMessage}
                          onClick={initiateGoogleSignIn}
                        />
                      </GoogleOAuthProvider>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div class="desktop-nav desktop-nav-three nav-area">
          <div class="container-fluid">
            <nav class="navbar navbar-expand-sm navbar-light ">
              <div
                class="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <div class="navbar-brand-logo ">
                  <a class="navbar-brand" href="/">
                    <img src={logo} alt="Logo" height={60} />
                  </a>
                </div>{" "}
                <div className="m-auto">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <NavLink
                        to="/"
                        class="nav-link"
                        activeClassName="active-link"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="/shop" class="nav-link">
                        Shop
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="/categories" class="nav-link">
                        Categories
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="/blogs" class="nav-link">
                        {" "}
                        Blog{" "}
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="/about" class="nav-link">
                        About
                      </NavLink>
                    </li>

                    <li class="nav-item">
                      <NavLink to="/contact" class="nav-link">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="others-options d-flex align-items-center">
                  <div class="optional-item"></div>
                  <div
                    class="navbar-nav"
                    style={{
                      width: "100%",
                      display: "flex",
                      // background:"red",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div class="optional-item">
                      <li class="nav-item">
                        {Token ? (
                          <NavLink to="/cart" class="nav-link">
                            {/* <a class="optional-item-cart">
                        <i class="bx bx-cart-alt"></i>
                        {Count?.count > 0 ? (
                          <span>{Count.count}</span>
                        ) : (
                          false
                        )}{" "}
                      </a> */}
                            {/* <div class="optional-item-cart"> */}
                            <div
                              onClick={() => handleLinkClick()}
                              style={{ display: "flex", width: "100%" }}
                            >
                              <AiOutlineShoppingCart
                                style={{
                                  fontSize: "23px",

                                  marginLeft: "-10px",
                                }}
                              />
                              {Count?.count > 0 ? (
                                <span style={activeLinkStyle}>
                                  {Count.count}
                                </span>
                              ) : (
                                false
                              )}
                            </div>{" "}
                            {/* </div> */}
                          </NavLink>
                        ) : (
                          <NavLink to="/login" class="nav-link">
                            {/* <a class="optional-item-cart">
                        <i class="bx bx-cart-alt"></i>
                        {Count?.count > 0 ? (
                          <span>{Count.count}</span>
                        ) : (
                          false
                        )}{" "}
                      </a> */}
                            {/* <div class="optional-item-cart"> */}
                            <div
                              onClick={() => handleLinkClick()}
                              style={{ display: "flex", width: "100%" }}
                            >
                              <AiOutlineShoppingCart
                                style={{
                                  fontSize: "23px",

                                  marginLeft: "-10px",
                                }}
                              />
                              {Count?.count > 0 ? (
                                <span style={activeLinkStyle}>
                                  {Count.count}
                                </span>
                              ) : (
                                false
                              )}
                            </div>{" "}
                            {/* </div> */}
                          </NavLink>
                        )}
                      </li>
                    </div>{" "}
                    {Token ? (
                      <div class="optional-item">
                        {" "}
                        <li class="nav-item">
                          <NavLink
                            className="optional-item-cart"
                            to="/profile"
                            style={{ display: "flex", marginTop: "-10px" }}
                          >
                            {" "}
                            <div
                              onClick={() => handleAcLinkClick()}
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                columnGap: "6px",
                              }}
                            >
                              <BiUser
                                style={{
                                  fontSize: "24px",
                                  marginBottom: "5px",
                                }}
                              />
                              <p style={{ textTransform: "capitalize" }}>
                                {name}
                              </p>
                            </div>
                            {/* <i
                              title="Account"
                              style={{ fontSize: "23px" }}
                              class="bx bx-user"
                            ></i>{" "}
                            <h6
                              style={{
                                fontSize: "18px",
                                marginTop: "5px",
                                marginLeft: "5px",
                              }}
                            >
                              Account
                            </h6> */}
                          </NavLink>
                        </li>
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div class="optional-item" style={{ marginTop: "-5px" }}>
                    {Token ? (
                      false
                    ) : (
                      <div
                        className="navbar-nav"
                        style={{
                          display: "flex",

                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "30px",
                          marginTop: "9px",
                        }}
                      >
                        <div className="nav-item">
                          <GoogleOAuthProvider
                            clientId={process.env.REACT_APP_CLIENT_ID}
                          >
                            <GoogleLogin
                              onSuccess={responseMessage}
                              onError={errorMessage}
                              onClick={initiateGoogleSignIn}
                            />
                          </GoogleOAuthProvider>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div class="mobile-nav">
                  <div class="mobile-other d-flex align-items-center">
                    <div class="optional-item">
                      <a class="optional-item-cart" href="/cart">
                        <i class="bx bx-cart-alt"></i>
                        {Count?.count > 0 ? (
                          <span>{Count.count}</span>
                        ) : (
                          false
                        )}{" "}
                      </a>
                    </div>{" "}
                    <div class="optional-item">
                      <a
                        class="topheader-btn"
                        href="#topheader"
                        data-bs-toggle="modal"
                        data-bs-target="#topheader"
                      >
                        <i class="bx bx-menu-alt-right"></i>
                      </a>
                    </div>
                    {Token ? (
                      <li className="hover" style={styles}>
                        <Link to="/Profile"> Profile</Link>{" "}
                      </li>
                    ) : (
                      <li className="hover" style={styles}>
                        <GoogleOAuthProvider
                          clientId={process.env.REACT_APP_CLIENT_ID}
                        >
                          <GoogleLogin
                            onSuccess={responseMessage}
                            onError={errorMessage}
                            onClick={initiateGoogleSignIn}
                          />
                        </GoogleOAuthProvider>
                      </li>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
