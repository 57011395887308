import React, { useState, useEffect } from "react";
import { globalLoderAction } from "../Redux/Action";
import { useDispatch } from "react-redux";

function TermsConditions() {
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="inner-banner inner-banner-bg10">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#f290bb" }}>Terms & Conditions</h3>
            <ul>
              <li>
                <a style={{ color: "#f9c113" }} href="/">
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>Terms & Conditions</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="terms-conditions-area pt-100 pb-70">
        <div class="container">
          <div class="section-title text-center">
            <div class="section-subtitle">
              <img src="assets/images/section-icon.png" alt="Section Images" />
              <span>Terms & Conditions</span>
            </div>
            <h4>beautytales Terms & Conditions</h4>
          </div>
          <div></div>
          <div class="row pt-45">
            <div class="col-lg-12">
              <div class="single-content">
                <p style={{ textAlign: "justify" }}>
                  beautytales provides the content and services available on
                  this website to you subject to the following Terms and
                  Conditions, our Privacy Policy, Payment Policy, and other
                  terms and conditions and policies which you may find
                  throughout our website in connection with certain
                  functionality, features, or promotions as well as customer
                  service, all of which are deemed a part of and included within
                  these Terms and Conditions (collectively, "Terms and
                  Conditions"). By accessing or using this website, you
                  acknowledge that you have read, understood, and agree, without
                  limitation or qualification, to be bound by these Terms and
                  Conditions in this Agreement, so please read this carefully
                  before proceeding.
                </p>
              </div>
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>Privacy</h6>
                <p style={{ textAlign: "justify" }}>
                  Please review our Privacy Policy to understand our privacy
                  practices.
                </p>
              </div>
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>Payment Policy</h6>
                <p style={{ textAlign: "justify" }}>
                  Please see our Payment Policy to understand the purchase
                  processes of our products. All international shipping orders
                  may attract local duties applicable in that country, and
                  accordingly, the customer will have to pay such local
                  duties/taxes.
                </p>
              </div>
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>
                  Products and Services for Personal Use
                </h6>
                <p style={{ textAlign: "justify" }}>
                  The products and services described on this website, and any
                  samples thereof we may provide to you, are for personal use
                  only. You may not sell or resell any of the products or
                  services, or samples thereof, you receive from us. We reserve
                  the right, with or without notice, to cancel or reduce the
                  quantity of any products or services provided to you that we
                  believe, in our sole discretion, may result in the violation
                  of our Terms and Conditions.
                </p>
              </div>
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>Accuracy of Information</h6>
                <p style={{ textAlign: "justify" }}>
                  We attempt to be as accurate as possible when describing our
                  products on the website. However, except to the extent implied
                  by applicable law, we do not warrant that the product
                  descriptions, colors, information, or other content available
                  on the website are accurate, complete, reliable, current, or
                  error-free.
                </p>
              </div>
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>Intellectual Property</h6>
                <p style={{ textAlign: "justify" }}>
                  All information and content available on the website and its
                  "look and feel," including but not limited to trademarks,
                  logos, service marks, text, graphics, logos, button icons,
                  images, audio clips, data compilations, and software, and the
                  compilation and organization thereof (collectively, the
                  "Content") is the property of beautytales, our Affiliates,
                  partners, or licensors, and is protected by laws of India,
                  including laws governing all applicable forms of intellectual
                  property.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Except as set forth in the limited licenses below, or as
                  required under applicable law, neither the Content nor any
                  portion of this website may be used, reproduced, duplicated,
                  copied, sold, resold, accessed, modified, or otherwise
                  exploited, in whole or in part, for any purpose without our
                  express, prior written consent.
                </p>
              </div>{" "}
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>Limited Licenses</h6>
                <p style={{ textAlign: "justify" }}>
                  We grant you a limited, revocable, and non-exclusive license
                  to access and make personal use of the beautytales website.
                  This limited license does not include the right to: (a) frame
                  or utilize framing techniques to enclose the website or any
                  portion thereof; (b) republish, redistribute, transmit, sell,
                  license, or download the website or any Content (except
                  caching or as necessary to view the website); (c) make any use
                  of the website or any Content other than personal use; (d)
                  modify, reverse engineer, or create any derivative works based
                  upon either the website or any Content; (e) collect account
                  information for the benefit of yourself or another party; (f)
                  use any meta tags or any other "hidden text" utilizing any
                  Content; or (g) use software robots, spiders, crawlers, or
                  similar data gathering and extraction tools, or take any other
                  action that may impose an unreasonable burden or load on our
                  infrastructure. You must retain, without modification, all
                  proprietary notices on the website or affixed to or contained
                  in the website.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We also grant you a limited, revocable, and non-exclusive
                  license to create a hyperlink to the home page of the website
                  for personal, non-commercial use only. A website that links to
                  the website (i) may link to, but not replicate, any Content;
                  (ii) may not imply that we are endorsing such website or its
                  services or products; (iii) may not misrepresent its
                  relationship with us; (iv) may not contain content that could
                  be construed as distasteful, obscene, offensive,
                  controversial, illegal, or inappropriate for any age; (v) may
                  not portray us or our products or services in a false,
                  misleading, derogatory, or otherwise offensive or
                  objectionable manner, or associate us with undesirable
                  products, services, or opinions; and (vi) may not link to any
                  page of the website other than the home page. We may, in our
                  sole discretion, request that you remove any link to the
                  website, and upon receipt of such request, you shall
                  immediately remove such link and cease any linking unless
                  separately and expressly authorized in writing by us to resume
                  linking.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Any unauthorized use by you of the beautytales website or any
                  Content automatically terminates the limited licenses set
                  forth in this Section without prejudice to any other remedy
                  provided by applicable law or these Terms and Conditions.
                </p>
              </div>{" "}
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>
                  Your Obligations and Responsibilities
                </h6>
                <p style={{ textAlign: "justify" }}>
                  In the access or use of the beautytales website, you shall
                  comply with these Terms and Conditions and the special
                  warnings or instructions for access or use posted on the
                  website. You shall always act in accordance with the law,
                  custom, and in good faith. You may not make any change or
                  alteration to the website or any Content or services that may
                  appear on this website and may not impair in any way the
                  integrity or operation of the website. Without limiting the
                  generality of any other provision of these Terms and
                  Conditions, if you default negligently or willfully in any of
                  the obligations set forth in these Terms and Conditions, you
                  shall be liable for all the losses and damages that this may
                  cause to beautytales, our Affiliates, partners, or licensors.
                </p>
              </div>{" "}
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>Third-Party Links</h6>
                <p style={{ textAlign: "justify" }}>
                  We are not responsible for the content of any off-website
                  pages or any other websites linked to or from the Beauty Tales
                  website. Links appearing on this website are for convenience
                  only and are not an endorsement by us, our affiliates, or our
                  partners of the referenced content, product, service, or
                  supplier. Your linking to or from any off-website pages or
                  other websites is at your own risk. We are in no way
                  responsible for examining or evaluating, and we do not warrant
                  the offerings of, off-website pages or any other websites
                  linked to or from the site, nor do we assume any
                  responsibility or liability for the actions, content,
                  products, or services of such pages and websites, including,
                  without limitation, their privacy policies and terms and
                  conditions. You should carefully review the terms and
                  conditions and privacy policies of all off-website pages and
                  other websites that you visit.
                </p>
              </div>{" "}
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>
                  Special Features, Functionality, and Events
                </h6>
                <p style={{ textAlign: "justify" }}>
                  beautytales may offer certain special features and
                  functionality or events (such as contests, promotions, or
                  other offerings) which may (a) be subject to terms of use,
                  rules, and/or policies in addition to or in lieu of these
                  Terms and Conditions; and (b) be offered by us or by third
                  parties. If so, we will notify you of this and if you choose
                  to take advantage of these offerings, you agree that your use
                  of those offerings will be subject to such additional or
                  separate terms of use, rules, and/or policies.
                </p>
              </div>{" "}
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>User Content</h6>
                <p style={{ textAlign: "justify" }}>
                  When you transmit, upload, post, email, or otherwise make
                  available data, text, software, music, sound, photographs,
                  graphics, images, videos, messages, or other materials ("User
                  Content") on the website, you are entirely responsible for
                  such User Content. This means that all third parties, and not
                  we, are entirely responsible for all User Content that they
                  post to the website.
                </p>
              </div>{" "}
              <div class="single-content">
                <h6 style={{ fontWeight: "700" }}>Submissions</h6>
                <p style={{ textAlign: "justify" }}>
                  It is our policy to decline unsolicited suggestions and ideas.
                  Notwithstanding our policy regarding unsolicited suggestions
                  and ideas, any inquiries, feedback, suggestions, ideas, or
                  other information you provide us (collectively, "Submissions")
                  will be treated as non-proprietary and non-confidential.
                  Subject to the terms of our Privacy Policy, by transmitting or
                  posting any Submission, you hereby grant us the right to copy,
                  use, reproduce, modify, adapt, translate, publish, license,
                  distribute, sell, or assign the Submission in any way as we
                  see fit, including but not limited to copying in whole or in
                  part, creating derivative works from, distributing, and
                  displaying any Submission in any form, media, or technology,
                  whether now known or hereafter developed, alone or as part of
                  other works, or using the Submission within or in connection
                  with our products or services. You also acknowledge that your
                  Submission will not be returned and we may use your
                  Submission, and any ideas, concepts, or know-how contained
                  therein, without payment of money or any other form of
                  consideration, for any purpose including, without limitation,
                  developing, manufacturing, distributing, and marketing
                  products.
                </p>
                <p style={{ textAlign: "justify" }}>
                  If you make a Submission, you represent and warrant that you
                  own or otherwise control the rights to your Submission. You
                  further represent and warrant that such Submission does not
                  constitute or contain software viruses, commercial
                  solicitation, chain letters, mass mailings, or any form of
                  "spam." You may not use a false email address, impersonate any
                  person or entity, or otherwise mislead us as to the origin of
                  any Submission. You agree to indemnify us for all claims
                  arising from or in connection with any claims to any rights in
                  any Submission or any damages arising from any Submission.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TermsConditions;
